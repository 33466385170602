import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import styles from './modal.module.scss';
import { IModal } from './utils/interface';

const Modal = ({ isOpen, onClickOutside, children, className }: IModal): null | JSX.Element => {
  const modalsContainer = document.getElementById('modals-container');
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    function handleClickOutside(event: Event) {
      if (
        (ref?.current && !ref?.current.contains(event.target as Node)) ||
        ref?.current?.isEqualNode(event.target as Node)
      ) {
        onClickOutside();
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, onClickOutside]);

  if (!modalsContainer || !isOpen) return null;
  return createPortal(
    <div data-testid="modal" ref={ref} className={`${styles._} ${className}`}>
      <div className={styles['wrapper']}>{children}</div>
    </div>,
    modalsContainer
  );
};

export default Modal;
