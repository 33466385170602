import useNetworkRequest from 'src/hooks/General/useNetworkRequest';
import { BASE_URL } from 'src/utilities/endpoints';
import { TConversationWithLeadChef, TCreateConversation, TGetALlLeadChefChat, TSingleConversation } from '../Types';

export default function useChatNetworkRequest(id?: string) {
  /*=============================================
    =            Create conversation           =
    =============================================*/

  const [{ data, isLoading: isCreatingConversation, error }, createConversation] =
    useNetworkRequest<unknown, TCreateConversation>(
      `${BASE_URL}/chats/start-conversation/${id }`,
      {
        method: 'POST',
      }
    );

  /*=============================================
    =            Send Message            =
    =============================================*/

  const [
    { data: sendMessageResponse, isLoading: isSendingMessage, error: sendMessageError },
    sendMessage,
  ] = useNetworkRequest<unknown, TCreateConversation>(
    `${BASE_URL}/chats/send-message/${id}`,
    {
      method: 'POST',
    }
  );

  /*=============================================
  =            Conversations with Leadchef            =
  =============================================*/

  const [
    {
      data: conversationWthLeadChefResponse,
      isLoading: isGettingConversations,
      error: getConversationsWithLeadChefError,
    },
    getConversationsWithLeadChef,
  ] = useNetworkRequest<unknown, TConversationWithLeadChef>(
    `${BASE_URL}/chats/conversation/with-leadchef`,
    {
      method: 'GET',
    }
  );

  /*=============================================
  =            Mark as read            =
  =============================================*/
  const [
    { data: markAsReadResponse, isLoading: isMarkingAsRead, error: isMarkAsReadError },
    MarkAsRead,
  ] = useNetworkRequest<unknown, TConversationWithLeadChef>(
    `${BASE_URL}/chats/messages/mark-as-read`,
    {
      method: 'PUT',
    }
  );

  //============= All chats with LeadChef ============/
  const [
    {
      data: allChatsWthLeadChefResponse,
      isLoading: isGettingChats,
      error: getChatsWithLeadChefError,
    },
    getChatsWithLeadChef,
  ] = useNetworkRequest<unknown, TGetALlLeadChefChat>(
    `${BASE_URL}/chats/leadchef`,
    {
      method: 'GET',
    }
  );
//======= get single conversatin ===============//
  const [
    {
      data: singleConversationResponse,
      isLoading: isGettingSingleConversation,
      error: getsingleConversationError,
    },
    getSingleConversation,
  ] = useNetworkRequest<unknown, TSingleConversation>(
    `${BASE_URL}/chats/conversation/${id}`,
    {
      method: 'GET',
    }
  );

  //================ End ======================//


  return {
    createConversation,
    error,
    isCreatingConversation,
    data,

    /*=============================================
    =            send message            =
    =============================================*/
    sendMessageResponse,
    isSendingMessage,
    sendMessageError,
    sendMessage,
    /*=============================================
     =            Section comment block            =
     =============================================*/

    conversationWthLeadChefResponse,
    isGettingConversations,
    getConversationsWithLeadChefError,
    getConversationsWithLeadChef,

    // =============================================
    allChatsWthLeadChefResponse,
    isGettingChats,
    getChatsWithLeadChef,
    getChatsWithLeadChefError,

    // ========================================
  singleConversationResponse,
  getSingleConversation,
  isGettingSingleConversation,
  getsingleConversationError,


    /*=============================================
    =            Mark as read            =
    =============================================*/
    markAsReadResponse,
    isMarkingAsRead,
    isMarkAsReadError,
    MarkAsRead,
  };

  

  

  
}
