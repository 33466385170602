import style from './style.module.scss'
import cancel from "../../../../../../../assets/icons/cross.png"


interface IProps{
    image: any
    reduceImage?: () => void
    onClose:()=>void
    // index: number

}
const EnlargedImage:React.FC<IProps> = ({image, onClose}) => {
  
    return(
        <div className={style['container']} >
            <div className={style['cancel']} onClick={onClose}>
                <img  src={cancel} alt=''  />
            </div>
           <div className={style['image']}>
            <img  src={image} alt=''/>
           </div>
            
        </div>
    )
}

export default EnlargedImage