// import axios, { ResponseType } from 'axios';
import React, { useEffect, useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import useNetworkRequest from 'src/hooks/General/useNetworkRequest';
import { Question, questionResponse } from '../../../../types/responses';
import { BASE_URL } from '../../../../utilities/endpoints';
import TriviaAnswer from '../TriviaAnswer/TriviaAnswer';
import TriviaCoupon from '../TriviaCoupon/TriviaCoupon';
import TriviaOption from '../TriviaOption/TriviaOption';

import styles from './TriviaQuestion.module.scss';

interface QuestionProps {
  candidateEmail: string;
}

const TriviaQuestion: React.FC<QuestionProps> = ({ candidateEmail }) => {
  const [questions, setQuestions] = useState<Question[]>([]);

  const [{ data: response, isLoading: isLoadingQuestion }, sendRequest] = useNetworkRequest<
    unknown,
    questionResponse
  >(`${BASE_URL}/question-set?page=1&limit=20`, {
    method: 'GET',
  });

  useEffect(() => {
    sendRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (response) {
      setQuestions(response.data.results[0].questions);
    }
  }, [response]);

  const [currentQuestion, setCurrentQuestion] = useState(0);
  let questionId = questions[currentQuestion]?._id;
  let answer = questions[currentQuestion]?.answer;

  const [score, setScore] = useState(0);
  const [correctAnswer, showCorrectAnswer] = useState(false);
  const [wrongAnswer, showWrongAnswer] = useState(false);
  const [coupon, setShowCoupon] = useState(false);
  const questionOptions = ['A', 'B', 'C', 'D'];

  const handleAnswerClick = (isCorrectAnswer: boolean) => {
    if (isCorrectAnswer) {
      showCorrectAnswer(true);
      setScore(score + 1);
    } else {
      showWrongAnswer(true);
    }
  };

  const handleContinueClick = () => {
    showWrongAnswer(false);
    showCorrectAnswer(false);
    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < questions.length) {
      setCurrentQuestion(nextQuestion);
    } else {
      setShowCoupon(true);
    }
  };

  useEffect(() => {
    if (questions.length > 0) {
    }
  }, [questions]);

  return (
    <div className={styles.container}>
      {!isLoadingQuestion ? (
        <div className={styles.question__wrapper}>
          <h3 className={styles.question__text}>
            {questions.length > 0 && questions[currentQuestion].question}
          </h3>
          <div className={styles.options__div}>
            {questions.length > 0 &&
              questions[currentQuestion].answerOptions.map((option: any, index: any) => {
                return (
                  <div
                    className={styles.single__option__div}
                    key={option.optionNumber}
                    onClick={() => handleAnswerClick(option.isCorrectAnswer)}
                  >
                    <TriviaOption
                      option={option.answerBody}
                      questionID={questionId}
                      optionNumber={option.optionNumber}
                      candidateEmail={candidateEmail}
                      questionOptions={questionOptions[index]}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      ) : (
        <div className={styles.loading_container}>
          <div className={styles.loading_wrapper}>
            <ClipLoader color={'#5935D5'} loading={isLoadingQuestion} size={100} />
            <p>Loading your quiz</p>
          </div>
        </div>
      )}

      {correctAnswer && (
        <TriviaAnswer
          decision="correct"
          answer={questions[currentQuestion]?.answerOptions[answer[0]].answerBody}
          handleContinueClick={handleContinueClick}
          coupon={coupon}
          setShowCoupon={setShowCoupon}
        />
      )}
      {wrongAnswer && (
        <TriviaAnswer
          decision="wrong"
          answer={questions[currentQuestion]?.answerOptions[answer[0]].answerBody}
          handleContinueClick={handleContinueClick}
          coupon={coupon}
          setShowCoupon={setShowCoupon}
        />
      )}
      {coupon && (
        <TriviaCoupon
          // {...score < questions.length? decision === "fail" : "success"}
          decision={score < questions.length ? 'fail' : 'success'}
          finalScore={score}
          totalQuestions={questions.length}
        />
      )}
    </div>
  );
};

export default TriviaQuestion;
