import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthenticationState, userData } from "../types";

const getAccessTokenFromLocalStorage = () => {
  const accessToken = localStorage.getItem("accessToken");
  if (accessToken) {
    return accessToken;
  }
  return null;
};

const getUserDataFromLocalStorage = () => {
  const userData = localStorage.getItem("userData");
  if (userData) {
    return JSON.parse(userData);
  }
  return null;
};

const initialState: AuthenticationState = {
  isLoggedIn: !!localStorage.getItem("accessToken") ?? false,
  accessToken: getAccessTokenFromLocalStorage(),
  userData: getUserDataFromLocalStorage(),
};



export const authenticationSlice = createSlice({
  name: "auth",
  reducers: {
    setLoginState: (state, action: PayloadAction<boolean>) => {
      state.isLoggedIn = action.payload;
    },
    setAccessToken: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload;
    },
    setUserData: (state, action: PayloadAction<userData>) => {
      state.userData = action.payload;
    },
    clearUserData: (state) => {
      localStorage.clear();
      state.userData = null;
      state.accessToken = null;
      state.isLoggedIn = false;
    },
    patchUserData: (state, action: PayloadAction<userData>) => {
      const newUserData = { ...state.userData, ...action.payload };
      state.userData = newUserData;
      localStorage.setItem("user", JSON.stringify(newUserData.user));
      localStorage.setItem("userData", JSON.stringify(newUserData));
    },
  },
  initialState,
});

export const {
  setLoginState,
  setUserData,
  setAccessToken,
  clearUserData,
  patchUserData,
} = authenticationSlice.actions;
export default authenticationSlice.reducer;
