import { useReducer } from 'react';
import { ReactNode } from 'react';
import { createContext } from 'react';

export type TSlideInformState = {
  isDraft: boolean;
  isEdit: boolean;
  isCreateNewVideo:boolean;
  InitialValues: initialValues | undefined;
};

type initialValues = {
  title: string;
  description: string;
  category?: string
  price?: any
  content: string;
  thumbnail: string;
  _id?: string;
};

export const SlideInFormContext = createContext<
  | {
          openForm: (
        params:
          | SlideInformActionTypes.SET_DRAFT_FLAG
          | SlideInformActionTypes.SET_EDIT_FLAG
          | SlideInformActionTypes.CLOSE_FORM
          | SlideInformActionTypes.RESET_VIDEO_ONLY
          | SlideInformActionTypes.SET_OPEN_FORM,
      ) => void;
      slideInFormState: TSlideInformState;
      setFormContent: (
        params: SlideInformActionTypes.SET_FORM_VALUES,
        payload: initialValues
      ) => void;
    }
  | undefined
>(undefined);

const initialState: TSlideInformState = {
  isDraft: false,
  isEdit: false,
  isCreateNewVideo:false,
  InitialValues: {
    title: '',
    description: '',
    content: '',
    thumbnail: '',
  },
};

export enum SlideInformActionTypes {
  SET_EDIT_FLAG = 'SET_EDIT_FLAG',
  SET_DRAFT_FLAG = 'SET_DRAFT_FLAG',
  CLOSE_FORM = 'CLOSE_FORM',
  SET_FORM_VALUES = 'SET_FORM_VALUES',
  RESET_VIDEO_ONLY = 'RESET_VIDEO_ONLY',
  //This makes use of context to open the new video form, but a better method could be used as there is a local state 
  // in the main video file - index.tsx which opens the the new video
  SET_OPEN_FORM = "SET_OPEN_FORM"
}

type TSlideInFormDispatchTypes = {
  type: SlideInformActionTypes;
  payload?: {
    title: string;
    description: string;
    content: string;
    thumbnail: string;
  };
};

//usereducer function
function reducer(state = initialState, action: TSlideInFormDispatchTypes): TSlideInformState {
  switch (action.type) {
    case 'SET_EDIT_FLAG':
      return {
        ...state,
        isEdit: !state.isEdit,
      };
    case 'SET_DRAFT_FLAG':
      return {
        ...state,
        isDraft: !state.isDraft,
      };
    case 'SET_FORM_VALUES':
      return {
        ...state,
        InitialValues: action.payload,
      };
    case 'SET_OPEN_FORM':
      return {
        ...state,
        isCreateNewVideo: !state.isCreateNewVideo,
      };
    case 'RESET_VIDEO_ONLY':
      return {
        ...state,
        InitialValues: {
          title: state.InitialValues?.title || '',
          description: state.InitialValues?.description || '',
          content: '',
          thumbnail: state.InitialValues?.thumbnail || '',
        },
      };
    case 'CLOSE_FORM':
      return {
        isEdit: false,
        isDraft: false,
        isCreateNewVideo:false,
        InitialValues: action.payload,
      };

    default:
      return state;
  }
}

export default function SlideInFormContextWrapper({ children }: { children: ReactNode }) {
  const [slideInFormState, dispatch] = useReducer(reducer, initialState);

  const openForm = (
    action:
      | SlideInformActionTypes.SET_DRAFT_FLAG
      | SlideInformActionTypes.SET_EDIT_FLAG
      | SlideInformActionTypes.CLOSE_FORM
      | SlideInformActionTypes.RESET_VIDEO_ONLY
      | SlideInformActionTypes.SET_OPEN_FORM
  ) => {
    dispatch({
      type: action,
    });
  };
  const setFormContent = (
    action: SlideInformActionTypes.SET_FORM_VALUES,
    payload: initialValues
  ) => {
    dispatch({
      type: action,
      payload,
    });
  };

  return (
    <SlideInFormContext.Provider value={{ slideInFormState, openForm, setFormContent }}>
      {children}
    </SlideInFormContext.Provider>
  );
}
