/* eslint-disable react/display-name */
import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src';
import useAbort from 'src/hooks/General/useAbort';
import { clearUserData, setLoginState } from 'src/redux/Slices/authSlice';

export interface IprotectedRoutes extends RouteProps {
  isAuth: boolean;
  isLeadChef: boolean;
}

export const LeadChefPrivateRoute = React.memo(
  ({ component: Component, isAuth, isLeadChef, ...rest }: IprotectedRoutes): JSX.Element | null => {
    const role = useAppSelector((state) => state.auth.userData?.user?.role);

    useAbort() // this aborts all requests when the component unmounts

    const dispatch = useAppDispatch();
    if (!Component) return null;

    const logout = () => {
      localStorage.removeItem('token');
      dispatch(clearUserData);
      dispatch(setLoginState(false));
    };

    return (
      <Route
        {...rest}
        render={(props) =>
          isAuth && role === 'lead_chef' ? (
            <Component {...props} />
          ) : (
            <>
              {logout()}

              <Redirect
                to={{
                  pathname: '/lead-chef/login',
                  state: {
                    from: props.location,
                    message: 'Please Login with the required credentials',
                  },
                }}
              />
            </>
          )
        }
      />
    );
  }
);
