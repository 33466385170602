import { useEffect, useMemo } from 'react';
import { userData } from 'src/redux/types';
import { useAppDispatch, useAppSelector } from '../../index';
import {
  clearUserData,
  setAccessToken,
  setLoginState,
  setUserData,
} from '../../redux/Slices/authSlice';

import { LoginResponseData, refreshToken, RefreshTokenResponseData } from '../../types/responses';
import { REFRESH_TOKEN } from '../../utilities/endpoints';
import useNetworkRequest from './useNetworkRequest';

export type Authenticate = (loginResponse: LoginResponseData) => void;

export interface Auth {
  isLoggedIn: boolean;
  authenticate: Authenticate;
  logout: () => void;
  isRefreshingToken: boolean;
  userData: userData | null;
}

const useAuth = (): Auth => {
  const dispatch = useAppDispatch();
  const { isLoggedIn, userData } = useAppSelector((state) => state.auth);
  const tokenObjectString = useMemo(() => localStorage.getItem('token'), []);
  const tokenObject: refreshToken = tokenObjectString ? JSON.parse(tokenObjectString) : null;

  //this handles the refresh token request
  const [{ data, isLoading: isRefreshingToken, error }, refresh] = useNetworkRequest<
    unknown,
    RefreshTokenResponseData
  >(REFRESH_TOKEN, {
    withCredentials: true,
    method: 'POST',
  });

  const authenticate: Authenticate = (loginResponse) => {
    if (loginResponse) {
      localStorage.setItem(
        'token',

        JSON.stringify(loginResponse.data.refreshToken)
      );
      localStorage.setItem(
        'userData',

        JSON.stringify(loginResponse.data)
      );
      localStorage.setItem(
        'accessToken',

        JSON.stringify(loginResponse.data.accessToken)
      );

      dispatch(setUserData(loginResponse.data));
      dispatch(setLoginState(true));
      dispatch(setAccessToken(loginResponse.data.accessToken));
    } else {
      throw Error('Access denied.');
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    dispatch(clearUserData);
    dispatch(setLoginState(false));
  };

  useEffect(() => {
    if (data)
      authenticate({
        ...data,
        refreshToken: tokenObject,
      } as unknown as LoginResponseData);

    // if (error) logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error]);

  useEffect(() => {
    if (tokenObject && !isLoggedIn) {
      refresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isLoggedIn, userData, authenticate, logout, isRefreshingToken };
};
export default useAuth;
