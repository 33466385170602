import style from './index.module.scss';
import { ReactComponent as WarnIcon } from '../../assets/icons/warn-icon.svg';
import { useAppSelector } from 'src';


export default function ClassEventNotice({
  handleClick,
  isActive,
  isDisabled
}: {
  isDisabled: boolean;
  isActive: boolean;
  handleClick: () => void;
}) {
  const user = useAppSelector((state) => state.auth.userData?.user);
  const showText = () => {
    switch (true) {
      case !isActive:
        return 'This class is Inactive';

      case isDisabled && isActive:
        return user?.role === 'lead_chef'
          ? 'You have disabled participant messaging to this classroom.'
          : 'The leadchef has disabled participant messaging to this classroom.';
    
      default:
        break;
    }
  };
  return (
    <div className={style['classNotice']}>
      <span>
        <WarnIcon className={style['icon']} />
      </span>
      <p className={style['text']}>{showText()}</p>
      {user?.role === 'lead_chef' && isDisabled && isActive && (
        <button type="button" onClick={handleClick} className={style['enable--btn']}>
          Enable
        </button>
      )}
    </div>
  );
}
