import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import { Button } from '../../../Button';
import DotsImage from './assets/images/Dots.svg';

import { AppModal } from '../../../Modal/index';
import { TriviaLoginDataResponse } from '../../../../types/responses';

import { BASE_URL } from '../../../../utilities/endpoints';

import Trivia from '../../Trivia';
import TriviaQuestion from '../TriviaQuestion/TriviaQuestion';
import useNetworkRequest from 'src/hooks/General/useNetworkRequest';

const TriviaLogin: React.FC = () => {
  const [candidateName, setCandidateName] = useState('');
  const [candidateEmail, setCandidateEmail] = useState('');
  const [createStatus, setCreateStatus] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [errorModal, setShowErrorModal] = useState(false);
  const [disclaimerModal, setShowDisclaimerModal] = useState(false);

  const [{ data: response, isLoading, error }, sendRequest] = useNetworkRequest<
    unknown,
    TriviaLoginDataResponse
  >(`${BASE_URL}/candidate`, {
    method: 'POST',
  });

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCandidateName(e.target.value);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCandidateEmail(e.target.value);
  };

  const handleSubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    sendRequest({
      name: `${candidateName}`,
      email: `${candidateEmail}`,
    });
  };

  useEffect(() => {
    if (error) {
      console.dir(error);
      setErrorMessage(error.response?.data.message);
      setShowErrorModal(true);
    }
  }, [error]);

  useEffect(() => {
    if (response) {
      setShowDisclaimerModal(true);
    }
  }, [response]);

  const closeErrorModal = () => {
    setErrorMessage('');
    setShowErrorModal(false);
  };

  const handleLoadQuestions = () => {
    if (response) {
      setCreateStatus(true);
      setShowDisclaimerModal(false);
    }
  };

  return (
    <Trivia>
      <div className={styles.body}>
        {createStatus ? (
          <TriviaQuestion candidateEmail={candidateEmail} />
        ) : (
          <div className={styles.form_section}>
            <h2>Please enter your email address below to get started</h2>
            <form action="" onSubmit={(e) => handleSubmitForm(e)}>
              <label>Name</label>
              <br />
              <input
                type="text"
                name="text"
                placeholder="Name"
                required
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleNameChange(e)}
              />
              <label>Email Address</label>
              <br />
              <input
                type="email"
                name="email"
                placeholder="name@example.com"
                required
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleEmailChange(e)}
              />

              <Button>{isLoading ? 'Please wait' : 'Get Started'}</Button>
            </form>
            <img src={DotsImage} alt="" className={styles.img_one} />
            <img src={DotsImage} alt="" className={styles.img_two} />
          </div>
        )}
        <AppModal isOpen={disclaimerModal} onClickOutside={() => setShowDisclaimerModal(false)}>
          <div className={styles['disclaimer']}>
            <h3>Disclaimer</h3>
            <p>
              Partaking in the Trivia and answering the questions correctly doesn’t automatically
              guarantee you a coupon code.
            </p>
            <p>
              Winners will be selected at random by an unbiased process. You’re always welcome to
              try again.
            </p>
            <div className={styles['action--area']}>
              <Button variant="plain" onClick={() => setShowDisclaimerModal(false)}>
                Cancel
              </Button>
              <Button variant="primary" onClick={handleLoadQuestions}>
                I Agree
              </Button>
            </div>
          </div>
        </AppModal>
        <AppModal isOpen={errorModal} onClickOutside={() => setShowErrorModal(false)}>
          <div className={styles.error_message_container}>
            <h3>
              {errorMessage && errorMessage?.includes('Duplicate') ? (
                <p>Sorry, you cannot partake in this Trivia more than once</p>
              ) : (
                <p>Please enter a valid email address</p>
              )}
            </h3>
            <Button className={styles.button} onClick={closeErrorModal}>
              Close
            </Button>
          </div>
        </AppModal>
      </div>
    </Trivia>
  );
};

export default TriviaLogin;
