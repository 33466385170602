import React from "react";
import styles from "./TriviaCoupon.module.css";
import congratIcon from "../../../../assets/images/congrat-sticker.png";
import tryLaterIcon from "../../../../assets/images/trylater-sticker.png";
import { NavLink } from "react-router-dom";

type decisionEnum = "success" | "fail";
interface couponInfoProps {
  decision: decisionEnum;
  finalScore: number;
  totalQuestions: number;
}

const TriviaCoupon: React.FC<couponInfoProps> = ({
  decision,
  finalScore,
  totalQuestions,
}) => {
  const selectedIcon = decision === "success" ? congratIcon : tryLaterIcon;

  const infoHead =
    decision === "success" ? "Congrats!" : "There is always another chance";

  // const infoText = decision === "success" ?
  // `You got ${finalScore}/${totalQuestions} and you qualify for a coupon code`
  //  : `You got ${finalScore}/${totalQuestions} questions right, you need to get
  //    all correctly to qualify for a coupon code`

  // const tinyInfo = decision === "success" ?
  // "A coupon code will be sent to your email address if you qualify"
  // : "You can always try again in another session"
  const infoText =
    decision === "success"
      ? `You got ${finalScore}/${totalQuestions} `
      : `You got ${finalScore}/${totalQuestions} questions right, you need to get
     all correctly to qualify for a coupon code`;

  const tinyInfo =
    decision === "success"
      ? "A coupon code will be sent to your email address if you qualify"
      : "You can always try again in another session";

  return (
    <div className={styles.wrapper__div}>
      <div className={styles.icon}>
        <img src={selectedIcon} alt="icon alt" />
      </div>
      <div className={styles.info__head}>{infoHead}</div>
      <span className={styles.option__text}>{infoText}</span>
      <div>
        <span className={styles.tiny__info}>{tinyInfo}</span>
      </div>

      <div className={styles.button__div}>
        <NavLink exact to="/">
          <button className={styles.button}>Continue</button>
        </NavLink>
      </div>
      <br></br>
      <br></br>
    </div>
  );
};

export default TriviaCoupon;
