import style from './Message.module.scss';
import Avatar from './assets/images/image.png';

function Message({ username, message, profileImg }: { username?: string; message?: string, profileImg:string }) {
  return (
    <div className={style['main-container']}>
      <img src={profileImg ?? Avatar} alt="" />
      <div className={style['message-info']}>
        <h4>{username}</h4>
        <p>{message}</p>
      </div>
    </div>
  );
}

export default Message;
