import { BASE_URL } from 'src/utilities/endpoints';
import { TCreateClass } from '../types';
import useNetworkRequest from '../General/useNetworkRequest';

export default function useSendComment(id: string) {
  const [{ data, isLoading: isCreatingClass, error }, sendMessage] = useNetworkRequest<
    unknown,
    TCreateClass
  >(`${BASE_URL}/classroom/create-comment/${id}`, {
    method: 'POST',
  });

  return { sendMessage, data, isLoading: isCreatingClass, error };
}
