import { SingleShopResult } from 'src/hooks/types';

export enum ActionKind {
  ADD_ITEM = 'ADD_ITEM',
  REMOVE_ITEM = 'REMOVE_ITEM',
  SHOW_CART = 'SHOW_CART',
  SUBTRACT_QUANTITY = 'SUBTRACT_QUANTITY',
}

// An interface for our actions
export type Action = Partial<{
  type: ActionKind;
  payload: Partial<SingleShopResult>;
}>;

export type State = {
  showCart: boolean;
  cartItems: SingleShopResult[];
};

export enum NotificationActionType {
  SHOW_NOTIFICATION = 'SHOW_NOTIFICATION',
  HIDE_NOTIFICATION = 'HIDE_NOTIFICATION',
  CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION',
  ISLOADING = 'ISLOADING',
}
// An interface for our actions
export type NotificationAction = Partial<{
  type: NotificationActionType;
  payload: TSingleNotificationItem | boolean;
}>;

export type TSingleNotificationItem = {
  type: 'error' | 'success';
  message: string;
};

export type NotificationState = {
  isLoading: boolean;
  showNotification: boolean;
  message: {
    type: 'error' | 'success';
    message: string;
  }[];
};
