import React from "react";
import "./FeatureCard.css";

function FeatureCard({ source, header, content }) {
  return (
    <div className="feature-card-container">
      <img src={source} alt={source} />
      <div className="feature-card-header">{header}</div>
      <div className="feature-card-content">{content}</div>
    </div>
  );
}

export default FeatureCard;
