import { AxiosError } from 'axios';
import { useState } from 'react';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useNetworkRequest from 'src/hooks/General/useNetworkRequest';
import useAuth, { Authenticate } from '../../../../hooks/General/useAuth';
import { LoginResponseData } from '../../../../types/responses';
import { BASE_URL } from '../../../../utilities/endpoints';

interface LoginRequestData {
  email: string;
  password: string;
}
interface login {
  login: (data: LoginRequestData) => void;
  isLoading: boolean;
  error: AxiosError | null;
  data?: LoginRequestData;
  reset: () => void;
  handleLogin: (userType: string, loginResponse: LoginRequestData) => void;
  authenticate: Authenticate;
}

export const useLogin = (): login => {
  const history = useHistory();
  const { authenticate, isLoggedIn } = useAuth();
  const { route } = useParams<{ route: string }>();
  const [userType, setUserType] = useState('');

  const [{ data: loginResponse, isLoading, error }, login, reset] = useNetworkRequest<
    LoginRequestData,
    LoginResponseData
  >(`${BASE_URL}/auth/login`, {
    method: 'POST',
  });

  const handleLogin = (userType: string, loginResponse: LoginRequestData) => {
    setUserType(userType);
    login(loginResponse);
  };

  const routeToDashboard = () => {
    switch (route?.toLowerCase()) {
      case 'lead-chef':
        history.push(
          //@ts-ignore
          history.location.state?.from || '/lead-chef/dashboard'
        );
        break;
      case 'admin':
        history.push(
          //@ts-ignore
          history.location.state?.from || '/admin/dashboard'
        );
        break;
      default:
        history.push(
          //@ts-ignore
          history.location.state?.from || '/discover'
        );
    }
  };

  useEffect(() => {
    if (loginResponse) {
      authenticate(loginResponse);
      routeToDashboard();
    }
    if (isLoggedIn) {
      routeToDashboard();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginResponse, isLoggedIn, userType]);

  return { login,  handleLogin, isLoading, error, reset, authenticate };
};
