import { AppModal } from 'src/components/Modal';
import { Button } from 'src/components/Button';
import { FaCheckSquare } from 'react-icons/fa';
import { MdThumbUp } from 'react-icons/md';
import { GrClose } from 'react-icons/gr';
import { useState } from 'react';
import style from './subscription-plans.module.scss';
import useLockBodyScroll from 'src/hooks/General/useLockBodyScroll';
import useNetworkRequest from 'src/hooks/General/useNetworkRequest';
import { BASE_URL } from 'src/utilities/endpoints';
import { useEffect } from 'react';
import { TUsersPricePoints, TPricePoint, TUsersPlans } from 'src/types/plans';
import { useAppDispatch, useAppSelector } from 'src';
import { TSubLog } from './types';
import { usePaystackPayment } from 'react-paystack';
import { patchUserData } from 'src/redux/Slices/authSlice';
import { IProfileImgupload } from 'src/pages/Profile/components/Profile-Picture/types';
// import PreOrderModal from '../PreOrderModal/PreOrderModal';

/**
 *
 * @param isOpen - boolean
 * @param onClose - function -> ()=>void
 * @returns JSX Element
 */
export default function SubscriptionPlans({
    isOpen,
    onClose,
}: {
    isOpen: boolean | undefined;
    onClose: () => void;
}) {
    const [activePlanIdx, setActivePlanIdx] = useState(0);
    useLockBodyScroll(isOpen);
    const updateUser = useAppDispatch();

    const user = useAppSelector(({ auth }) => auth.userData?.user);

    const [{ data: allPlansResponse }, getAllPlans] = useNetworkRequest<unknown, TUsersPlans>(
        `${BASE_URL}/plans/`,
        {
            method: 'GET',
        },
    );

    useEffect(() => {
        getAllPlans();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [pricePointId, setPricePointId] = useState<TPricePoint | string>();
    const [subId, setSubId] = useState<TUsersPricePoints | string | undefined>('');

    const [loadRegular, setLoadRegular] = useState(false);

    const handleRegularSelect = () => {
        setSubId(allPlansResponse?.data[0]?._id);
        setLoadRegular(true);
        if (activePlanIdx === 0) {
            setPricePointId(allPlansResponse?.data[0]?.pricePoints[1]?._id);
        } else if (activePlanIdx === 1) {
            setPricePointId(allPlansResponse?.data[0]?.pricePoints[0]?._id);
        } else if (activePlanIdx === 2) {
            setPricePointId(allPlansResponse?.data[0]?.pricePoints[2]?._id);
        }
    };

    const handlePremiumSelect = () => {
        setSubId(allPlansResponse?.data[1]?._id);
        if (activePlanIdx === 0) {
            setPricePointId(allPlansResponse?.data[1]?.pricePoints[1]?._id);
        } else if (activePlanIdx === 1) {
            setPricePointId(allPlansResponse?.data[1]?.pricePoints[0]?._id);
        } else if (activePlanIdx === 2) {
            setPricePointId(allPlansResponse?.data[1]?.pricePoints[2]?._id);
        }
    };

    const [{ data: addSubLogResponse, isLoading: isLoadingSubLog }, addSubLog] = useNetworkRequest<
        unknown,
        TSubLog
    >(`${BASE_URL}/subscription/add-subscription-payment-log`, {
        method: 'POST',
    });

    const [{ data: profileResponse }, fetchUserProfile] = useNetworkRequest<
        unknown,
        IProfileImgupload
    >(`${BASE_URL}/user/profile`, {
        method: 'GET',
    });

    useEffect(() => {
        if (pricePointId) {
            addSubLog({
                email: `${user?.email}`,
                name: `${user?.name}`,
                context: 'order',
                pricePointId: `${pricePointId}`,
                subscriptionPlanId: `${subId}`,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pricePointId]);

    const payStackConfig: any = {
        amount: addSubLogResponse && addSubLogResponse?.data?.amount * 100,
        email: user?.email as string,
        name: user?.name as string,
        publicKey: process.env.REACT_APP_PAYSTACK as string,
        reference: addSubLogResponse && addSubLogResponse?.data?.reference,
    };

    const initializePayment = usePaystackPayment(payStackConfig);

    const onSuccess = () => {
        // Implementation for whatever you want to do with reference and after success call.
        fetchUserProfile();
    };

    useEffect(() => {
        if (addSubLogResponse) {
            initializePayment(onSuccess, onClose);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addSubLogResponse]);

    useEffect(() => {
        if (profileResponse) {
            const userData = {
                user: {
                    ...profileResponse.data,
                },
            };
            updateUser(patchUserData(userData));
            onClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profileResponse]);

    const planPrices = (key: number) => {
        switch (key) {
            case 0:
                return {
                    regular: 5000,
                    premium: 8000,
                };
            case 1:
                return {
                    regular: 25000,
                    premium: 50000,
                };
            case 2:
                return {
                    regular: 50000,
                    premium: 100000,
                };

            default:
                throw new Error('Unhandled price index');
        }
    };

    return (
        <AppModal isOpen={isOpen} onClickOutside={onClose}>
            <div className={style['appmodal--body']}>
                <header className={style['appmodal--body-title']}>
                    <div>
                        <h1>Select a Plan</h1>
                        <p>Pick the perfect plan tailored to your culinary needs.</p>
                    </div>

                    <GrClose className={style['appmodal--body-close']} onClick={onClose} />
                </header>

                <ul className={style['appmodal--body-list-options']}>
                    <li
                        className={activePlanIdx === 0 ? style['active-selection'] : ''}
                        onClick={() => setActivePlanIdx(0)}
                    >
                        Monthly
                    </li>
                    <li
                        className={activePlanIdx === 1 ? style['active-selection'] : ''}
                        onClick={() => setActivePlanIdx(1)}
                    >
                        Bi-Annually
                    </li>
                    <li
                        className={activePlanIdx === 2 ? style['active-selection'] : ''}
                        onClick={() => setActivePlanIdx(2)}
                    >
                        Annually
                    </li>
                </ul>
                <div className={style['appmodal--body-benefits']}>
                    <div className={`${style['appmodal--body-benefits-card']} ${style['regular']}`}>
                        <div>
                            <h2>Regular</h2>
                            <div className={style['appmodal--body-price']}>
                                <span className={style['appmodal--body-price-currency']}>NGN</span>
                                <span className={style['appmodal--body-price-amount']}>
                                    {planPrices(activePlanIdx).regular.toLocaleString()}
                                </span>
                                <span className={style['appmodal--body-price-duration']}>/mo</span>
                            </div>
                            <ul className={style['appmodal--body-benefits-list']}>
                                <li>
                                    <FaCheckSquare className={style['fa-checkSquare']} /> Recipes
                                    and cooking methods
                                </li>
                                <li>
                                    <FaCheckSquare className={style['fa-checkSquare']} /> Save
                                    recipes to view later
                                </li>
                                <li>
                                    <FaCheckSquare className={style['fa-checkSquare']} /> Free
                                    access to live webinars
                                </li>
                            </ul>
                        </div>

                        <Button variant="secondary" type="button" onClick={handleRegularSelect}>
                            {isLoadingSubLog && loadRegular ? 'Loading...' : 'Select plan'}
                        </Button>
                    </div>
                    <div className={`${style['appmodal--body-benefits-card']} ${style['premium']}`}>
                        <p className={style['recommended-plan']}>
                            <MdThumbUp className={style['recommended-plan--icon']} />{' '}
                            <span>RECOMMENDED</span>
                        </p>
                        <h2>Premium</h2>
                        <div className={style['appmodal--body-price']}>
                            <span className={style['appmodal--body-price-currency']}>NGN</span>
                            <span className={style['appmodal--body-price-amount']}>
                                {planPrices(activePlanIdx).premium.toLocaleString()}
                            </span>
                            <span className={style['appmodal--body-price-duration']}>/mo</span>
                        </div>
                        <ul className={style['appmodal--body-benefits-list']}>
                            <li>
                                <FaCheckSquare className={style['fa-checkSquare']} /> Recipes and
                                cooking methods
                            </li>
                            <li>
                                <FaCheckSquare className={style['fa-checkSquare']} /> Save recipes
                                to view later
                            </li>
                            <li>
                                <FaCheckSquare className={style['fa-checkSquare']} /> Free access to
                                live webinars
                            </li>
                            <li>
                                <FaCheckSquare className={style['fa-checkSquare']} /> Free access to
                                occasional “meet and eat” with Lead Chef
                            </li>
                            <li>
                                <FaCheckSquare className={style['fa-checkSquare']} /> Direct
                                messaging with Lead Chef
                            </li>
                            <li>
                                <FaCheckSquare className={style['fa-checkSquare']} /> Merchandise
                                and other freebies
                            </li>
                        </ul>

                        <Button variant="secondary" type="button" onClick={handlePremiumSelect}>
                            {isLoadingSubLog && !loadRegular ? 'Loading...' : 'Select plan'}
                        </Button>
                    </div>
                </div>
            </div>
        </AppModal>
    );
}
