import { Route, Switch, useLocation } from 'react-router-dom';
import { PreOrderCheckout } from 'src/components/PreOrderCheckout';
import { PrivateRoute } from 'src/components/ProtectedRoutes/NonAdmin';
import { TriviaLogin } from 'src/components/Trivia';
import { Login } from 'src/pages/Auth/Login';
import Landing from 'src/pages/Landing';
import { PreOrder } from 'src/components/LandingPage/PreOrder';
import CartContextProvider from 'src/context/CartContext/cart-context';
import { SingleMessageView } from 'src/components/Layout/Dashboard/components';
import { ChatSupportModal } from 'src/components/Layout/Dashboard/components/UserActions/TopBarActions';
//import AllComplaints from 'src/pages/Main/Complaints/subroute/AllComplaints';
import React from 'react';
import AuthModalProvider from 'src/context/AuthModalContext/authModalContext';
const Recipes = React.lazy(() => import('src/pages/Main/Recipes'));
const Application = React.lazy(
    () => import('src/pages/Main/LiveWebinar/Application/webinar-application'),
);
const HelpAndSupport = React.lazy(() => import('src/pages/Main/Complaints'));
const Webinar = React.lazy(() => import('src/pages/Webinar'));
const Profile = React.lazy(() => import('src/pages/Profile'));
const ViewSingleRecipe = React.lazy(
    () => import('src/pages/Main/Recipes/subroutes/ViewSingleRecipe'),
);
const ViewSingleVideo = React.lazy(
    () => import('src/pages/Main/Discover/subroutes/ViewSingleVideo'),
);
const Checkout = React.lazy(() => import('src/pages/Main/Shop/component/checkout'));
const ViewSingleShopItem = React.lazy(() => import('src/pages/Auth/Forgot-Password'));
const Shop = React.lazy(() => import('src/pages/Main/Shop'));
const AudioVisualization = React.lazy(
    () => import('src/pages/Main/Classes/containers/AudioVisualization/audioVisualization'),
);
const ForgotPassword = React.lazy(() => import('src/pages/Auth/Forgot-Password'));
const ResetPassword = React.lazy(() => import('src/pages/Auth/Reset-Password'));
const SignUp = React.lazy(() => import('src/pages/Auth/Sign-up'));
const VerifyUser = React.lazy(() => import('src/pages/Auth/VerifyUser'));
const Register = React.lazy(() => import('src/components/LandingPage/Register'));
const AboutUs = React.lazy(() => import('src/components/LandingPage/AboutUs'));
const Contact = React.lazy(() => import('src/components/LandingPage/Contact'));
const Testimonials = React.lazy(() => import('src/components/LandingPage/Testimonials'));
const Trivia = React.lazy(() => import('src/components/LandingPage/Trivia'));
const Classes = React.lazy(() => import('src/pages/Main/Classes'));
const ViewSingleClass = React.lazy(() => import('src/pages/Main/Classes/subroute/single-class'));
const Collections = React.lazy(() => import('src/pages/Main/Collections'));
const Discover = React.lazy(() => import('src/pages/Main/Discover'));
const AllComplaints = React.lazy(() => import('src/pages/Main/Complaints/subroute/AllComplaints'));

const MainRoutes = ({ isLoggedIn }: { isLoggedIn: boolean }) => {
    const location = useLocation();
    type locationState = {
        state: {
            background: string;
            settings: string;
        };
    };
    let background = location.state && (location as locationState).state.background;
    let settings = location.state && (location as locationState).state.settings;

    return (
        <>
            <Switch //@ts-ignore
                location={background || settings || location}
            >
                <Route exact path="/Contact" component={Contact} />
                <Route exact path="/Trivia" component={TriviaLogin} />
                <Route exact path="/" component={Landing} />
                <Route exact path="/AboutUs" component={AboutUs} />
                <Route exact path="/Trivia" component={Trivia} />
                <Route exact path="/Testimonials" component={Testimonials} />
                <Route exact path="/sign-up" component={SignUp} />
                <Route exact path="/pre-order/subscription/:id" component={PreOrder} />
                <Route exact path="/pre-order/checkout" component={PreOrderCheckout} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/get-started" component={Register} />
                <Route exact path="/forgot-password" component={ForgotPassword} />
                <Route exact path="/auth/reset-password/:token" component={ResetPassword} />
                <Route exact path="/verify" component={VerifyUser} />
                <Route exact path="/test" component={AudioVisualization} />

                <AuthModalProvider>
                    <PrivateRoute
                        exact
                        path="/collections"
                        component={Collections}
                        isAuth={isLoggedIn}
                    />

                    {/* Protected routes start */}
                    <PrivateRoute exact path="/discover" component={Discover} isAuth={isLoggedIn} />
                    <PrivateRoute
                        exact
                        path="/discover/:route"
                        component={ViewSingleVideo}
                        isAuth={isLoggedIn}
                    />

                    <PrivateRoute exact path="/recipes" component={Recipes} isAuth={isLoggedIn} />
                    <PrivateRoute
                        exact
                        path="/recipes/:recipeId"
                        component={Recipes}
                        isAuth={isLoggedIn}
                    />
                    <PrivateRoute
                        exact
                        path="/recipes/:route/:recipeId"
                        component={ViewSingleRecipe}
                        isAuth={isLoggedIn}
                    />

                    <PrivateRoute exact path="/classes" component={Classes} isAuth={isLoggedIn} />
                    <PrivateRoute
                        exact
                        path="/classes/:route"
                        component={ViewSingleClass}
                        isAuth={isLoggedIn}
                    />

                    {/* Live Webinar */}
                    <PrivateRoute
                        exact
                        path="/live-webinar"
                        component={Webinar}
                        isAuth={isLoggedIn}
                    />

                    <PrivateRoute
                        exact
                        path="/live-webinar/:webinarId"
                        component={Application}
                        isAuth={isLoggedIn}
                    />

                    <PrivateRoute exact path="/profile" component={Profile} isAuth={isLoggedIn} />

                    <PrivateRoute
                        exact
                        path="/help"
                        component={AllComplaints}
                        isAuth={isLoggedIn}
                    />

                    <PrivateRoute
                        exact
                        path="/create-complaint"
                        component={HelpAndSupport}
                        isAuth={isLoggedIn}
                    />

                    {/* Shop */}
                    <CartContextProvider>
                        <PrivateRoute exact path="/shop" component={Shop} isAuth={isLoggedIn} />
                        <PrivateRoute
                            exact
                            path="/shop/:route"
                            component={ViewSingleShopItem}
                            isAuth={isLoggedIn}
                        />
                        <PrivateRoute
                            exact
                            path="/shop/cart/checkout"
                            component={Checkout}
                            isAuth={isLoggedIn}
                        />
                    </CartContextProvider>
                </AuthModalProvider>
            </Switch>
            <AuthModalProvider>
                {background && <Route path="/messages/:id/chat" children={<SingleMessageView />} />}
                {settings && (
                    <Route path="/help/:conversationId/chat" children={<ChatSupportModal />} />
                )}
            </AuthModalProvider>
        </>
    );
};

export default MainRoutes;
