import { AxiosError } from 'axios';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import useNetworkRequest from 'src/hooks/General/useNetworkRequest';
import useAuth from '../../../../hooks/General/useAuth';
import { LoginResponseData } from '../../../../types/responses';
import { BASE_URL } from '../../../../utilities/endpoints';

interface AcceptInviteForm {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  phone: string;
  confirmPassword: string;
}

interface AcceptInvite {
  acceptInvite: (data: AcceptInviteForm) => void;
  isLoading: boolean;
  requestError: AxiosError | null;
  reset: () => void;
  inviteResponse: LoginResponseData | null;
}

export const useAcceptInvite = (): AcceptInvite => {
  const history = useHistory();
  const { token: inviteToken } = useParams<{ token: string }>();
  const { authenticate } = useAuth();

  const [{ data: inviteResponse, isLoading, error: requestError }, sendAcceptInvite, reset] =
    useNetworkRequest<unknown, LoginResponseData>(`${BASE_URL}/admin/invite/setup/${inviteToken}`, {
      method: 'POST',
    });

  const acceptInvite = (formData: AcceptInviteForm) => {
    sendAcceptInvite({
      name: `${formData.firstName} ${formData.lastName}`,
      phoneNumber: formData.phone,
      password: formData.password,
      passwordConfirm: formData.confirmPassword,
    });
  };

  useEffect(() => {
    if (inviteResponse) {
      authenticate(inviteResponse);
      history.push('/admin/dashboard');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inviteResponse]);

  return { inviteResponse, acceptInvite, isLoading, requestError, reset };
};
