import React from 'react';
import image_one from './assets/images/image_one.jpg';
import image_two from './assets/images/image_two.jpg';
import image_three from './assets/images/image_three.jpg';
import image_four from './assets/images/image_four.jpg';
import { Button } from '../Button';
import styles from './Header.module.scss';
import { Link } from 'react-router-dom';

const Header = () => {
  // function jump(){
  //   var element = document.getElementById('section1');
  //   var headerOffset = 65;
  //   var elementOffset = element.getBoundingClientRect().top;
  //   var total = elementOffset - headerOffset;
  //   window.scrollBy(0, total);
  // }
  function scrollToSubscriptions() {
    let element = document.getElementById('subscription-section');
    let elementOffset = element.getBoundingClientRect().top;
    window.scrollBy(0, elementOffset);
  }
  return (
    <header>
      <div className={styles.container}>
        <div className={styles.header_images}>
          <div className={styles.column}>
            <img src={image_one} alt="" />
            <img src={image_two} alt="" />
          </div>

          <div className={styles.column}>
            <img src={image_three} alt="" />
            <img src={image_four} alt="" />
          </div>
        </div>
        <div className={styles.header_info}>
          <h1>
            Good food within <br /> your reach
          </h1>
          <p>
            Cooking should be a fun activity. Ify’s Kitchen makes sure of that by unlocking
            palatable meal recipes with ingredients within your reach.
          </p>
          {/* <p>
            Take part in our bi-weekly trivia and get a chance to earn discounts
            when we finally launch!
          </p> */}
          <div className={styles.info_buttons}>
            <Link to="/login">
              <Button className={styles.btn} variant="primary">
                Login
              </Button>
            </Link>
            <div onClick={scrollToSubscriptions} className={styles.button_variant}>
              <Link to="/sign-up">
                <Button
                  variant="outline"
                  btnColor={styles.btnColor}
                  btnBgColor={styles.btnBgColor}
                  className={styles.btn}
                >
                  Sign up
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
