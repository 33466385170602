import useNetworkRequest from 'src/hooks/General/useNetworkRequest';
import { BASE_URL } from 'src/utilities/endpoints';

type Notifications = {
  status: string;
  message: string;
  data: {
    currentPage: number;
    nextPage: number;
    prevPage: null;
    totalRecords: number;
    totalPages: number;
    results: SingleNotification[];
  };
};

export type SingleNotification = {
  sender: string;
  actionType: string;
  action: null;
  hasRead: boolean;
  _id: string;
  receiver: string;
  notificationTitle: string;
  notificationMessage: string;
  createdAt: string;
};

export default function useNotificationsNetworkRequest(n?:number) {
  const [{ data: response, isLoading: isFetchingNotification }, getAllNotifications] =
    useNetworkRequest<unknown, Notifications>(`${BASE_URL}/notifications`, {
      method: 'GET',
    });

    
    /*=============================================
    =            Mark N notifications as read            =
    =============================================*/
    const [{ data: markNotifications, isLoading: isMarkingNotifications }, markNnotifications] =
    useNetworkRequest<unknown, Notifications>(`${BASE_URL}/notifications/mark-as-read/${n}`, {
      method: 'PATCH',
    });

    
    
    
    

  return { response, isFetchingNotification, getAllNotifications
  
  , 

/*=============================================
=            mark n notifications as read            =
=============================================*/

markNotifications,
isMarkingNotifications,
markNnotifications

};
}
