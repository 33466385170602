import React from 'react';
import './ListItem.css';
import TickBlue from '../../Iconly/TickBlue.svg';
import TickWhite from '../../Iconly/TickWhite.svg';

function ListItem({ item, tickColor, itemColor }) {
  return (
    <div className="list-item-container">
      {tickColor === 'blue' && <img src={TickBlue} alt={TickBlue} className="tick-vector" />}
      {tickColor === 'white' && <img src={TickWhite} alt={TickWhite} className="tick-vector" />}
      <div className={`item ${itemColor}`}>{item}</div>
    </div>
  );
}

export default ListItem;
