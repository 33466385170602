import { useEffect, useState } from 'react';
import SubscriptionCard from '../../SubscriptionCard/SubscriptionCard';
import Dots from 'src/Iconly/Dots.svg';
import './Subscriptions.css';
import { BASE_URL } from '../../../utilities/endpoints';
import useNetworkRequest from '../../../hooks/General/useNetworkRequest';
import { TPlans, TSubscription } from '../../../types/plans';

function Subscriptions() {
    const [{ data: response }, getPlans] = useNetworkRequest<unknown, TPlans>(`${BASE_URL}/plans`, {
        method: 'GET',
    });

    const [plans, setPlans] = useState<TSubscription | null>(null);

    useEffect(() => {
        getPlans();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (response) {
            const Tsubscription = response.data;

            setPlans(Tsubscription as unknown as TSubscription);
        }
    }, [response]);

    return (
        <section id="subscription-section" className="subscription-section">
            <img className="dots-vector dots-vector-up" alt="" src={Dots} />
            <img className="dots-vector dots-vector-down" alt="" src={Dots} />
            <div className="subscription-title">
                Choose a suitable
                <br /> subscription plan
            </div>
            <div className="subscription-sub-title">
                Be rest assured that we’ll <br /> take care of the rest!
            </div>
            <div className="subscriptions-container">
                <SubscriptionCard
                    header="Regular"
                    items={plans && plans[0].planFeatures}
                    plans={plans}
                />

                <SubscriptionCard
                    header="Premium"
                    items={plans && plans[1].planFeatures}
                    plans={plans}
                />
            </div>
        </section>
    );
}

export default Subscriptions;
