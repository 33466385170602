import React, { useState } from "react";
import "./FAQCard.css";
import ArrowUp from "../../Iconly/ArrowUp.svg";

function FAQCard({ question, answer }) {
  const [toggle, setToggle] = useState(false);

  function toggleHandler() {
    setToggle(!toggle);
  }
  return (
    <div onClick={toggleHandler} className="FAQ-card-container">
      <div className="FAQ-question-container">
        <div className="FAQ-question">{question}</div>
        {toggle ? (
          <img
            onClick={toggleHandler}
            src={ArrowUp}
            className="arrow arrow-up"
            alt={ArrowUp}
          />
        ) : (
          <img
            onClick={toggleHandler}
            src={ArrowUp}
            className="arrow arrow-down"
            alt={ArrowUp}
          />
        )}
      </div>
      {toggle ? (
        <div className="FAQ-answer open">{answer}</div>
      ) : (
        <div className="FAQ-answer close">{answer}</div>
      )}
    </div>
  );
}

export default FAQCard;
