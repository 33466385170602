import { useState } from 'react';
import { comments } from 'src/hooks/types';
import EnlargedImage from '../enlargedImage';
import { v4 as uuidv4 } from 'uuid';
import style from '../../index.module.scss';

interface Iprops {
  messageItem: comments;
}
const SmallImage: React.FC<Iprops> = ({ messageItem }) => {
  const [enlarge, setEnlarge] = useState(false);

  const handleEnlargeImage = () => {
    setEnlarge(!enlarge);
  };

  return (
    <>
      {messageItem.imageUrls && messageItem.imageUrls?.length > 0 && (
        <div>
          <div className={style['chat--content']}>
            <div className={style['sent__images']}>
              {messageItem.imageUrls.map((item) => (
                <>
                  <div onClick={handleEnlargeImage}>
                    <img key={uuidv4()} src={item} alt={item} />
                  </div>

                  {enlarge && <EnlargedImage image={item} onClose={handleEnlargeImage} />}
                </>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SmallImage;
