import React from 'react';
import styles from  "./Trivia.module.css";

const Trivia : React.FC = ({ children }) => {
  return (
    <div className={styles.trivia__wrapper}>
      <div className={styles.title}>Ify's Trivia</div>
      {children}
    </div>
  )
};

export default Trivia;
