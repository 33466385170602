import React from "react";
import { Link } from "react-router-dom";
import { Button } from "../Button";
import FAQCard from "../FAQCard/FAQCard";
import "./FAQS.css";

function FAQS() {
  const FAQS = [
    {
      question: "How does Ify’s Kitchen work?",
      answer:
        "We make it easy for you to prepare palatable home-made meals while making sure you don’t break the bank. We make sure of this by creating recipes with ingredients within your reach. Take part in our bi-weekly trivia and get a chance to earn discounts when we finally launch!",
    },
    {
      question: "How do I pay for my subscription?",
      answer:
        "Fill in the payment section with your debit card information at checkout. You can also save your debit card to speed up your checkout process for future use.",
    },
    {
      question: "What debit cards are accepted?",
      answer:
        "We accept all major debit cards including Visa, MasterCard, and Verve debit cards.",
    },
    {
      question: "What kind of recipes can I expect?",
      answer:
        "Our wide selection of recipes allows you to choose from many cuisine types. Best of all we’re always adding new ones, lookout for your new favorite recipes when we launch!",
    },
    {
      question: "Are the recipes Vegan friendly?",
      answer:
        "They definitely are. The recipes provided are selected to cater for both vegan and non vegan diets alike.",
    },
  ];
  return (
    <section id="FAQ-section" className="FAQ-section">
      <div className="FAQ-title">Frequently Asked Questions</div>
      <div className="FAQS-container">
        {FAQS.map((FAQ, index) => {
          return (
            <FAQCard key={index} question={FAQ.question} answer={FAQ.answer} />
          );
        })}
      </div>
      <div>
        <Link to="/sign-up">
          <Button
            className="btnColor"
          >
            Sign up
          </Button>
        </Link>
      </div>
    </section>
  );
}

export default FAQS;
