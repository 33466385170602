import { useCallback, useState } from 'react';

export type TrecordProps = {
  recordString: string | null;
  handleRecord: () => void;
  audioStream: MediaStream | null;
  audioBlob: Blob | null;
  isRecording: boolean;
  resetRecordState: () => void;
};

export default function useRecorder(): TrecordProps {
  const [recordString, setRecordString] = useState<string | null>(null);
  const [audioStream, setAudioStream] = useState<MediaStream | null>(null);
  const [recordedChunk, setRecordedChunk] = useState([]);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null);
  const [isRecording, setIsRecording] = useState<boolean>(false);

  const startRecording = useCallback(() => {
    let mediaRecorderstate: MediaRecorder;
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        mediaRecorderstate = new MediaRecorder(stream);
        setMediaRecorder(mediaRecorderstate);

        const chunks: MediaRecorderDataAvailableEvent['data'] = [];
        mediaRecorderstate.start(100);

        mediaRecorderstate.ondataavailable = (e) => {
          if (e.data && e.data.size > 0) {
            chunks.push(e.data);
            setRecordedChunk(chunks);
          }
        };
        setAudioStream(stream);
      })
      .catch(() => console.log('An error occurred, please try again'));
  }, []);

  const stopRecording = useCallback(() => {
    if (!mediaRecorder) return;

    mediaRecorder.stop();

    mediaRecorder.onstop = () => {
      const blob = new Blob(recordedChunk, { type: 'audio/ogg; codecs=opus' });
      const reader = new FileReader();
      reader.readAsDataURL(blob);

      reader.onload = () => {
        setAudioBlob(blob);
        setRecordString(reader.result as string);
      };
    };

    audioStream?.getTracks().forEach((track) => track.stop());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaRecorder?.state, recordedChunk]);

  const handleRecord = () => {
    const mediaRecorderState = mediaRecorder?.state;

    mediaRecorder?.state === 'recording' ? stopRecording() : startRecording();

    mediaRecorderState === 'recording' ? setIsRecording(false) : setIsRecording(true);
  };

  const resetRecordState = () => {
    setAudioBlob(null);
    setRecordString(null);
    setIsRecording(false);
  };

  return { handleRecord, recordString, audioStream, audioBlob, isRecording, resetRecordState };
}
