import { ReactFacebookFailureResponse, ReactFacebookLoginInfo } from 'react-facebook-login';
//@ts-expect-error no_typescript definition
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin, { GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import GoogleIcon from './assets/icons/Google-icon.svg';
import FacebookIcon from './assets/icons/facebook-icon.svg';
import AppleIcon from './assets/icons/apple-icon.svg';
import style from './SocialMediaSignIn.module.scss';
import { NavLink } from 'react-router-dom';
import { useEffect } from 'react';

type TSocialMediaSignInProps = {
  loginWithFacebook: (body?: unknown, params?: unknown) => void;
  loginWithGoogle: (body?: unknown, params?: unknown) => void;
  authenticationType: 'login' | 'register';
};

export default function SocialMediaSignIn({
  loginWithGoogle,
  loginWithFacebook,
  authenticationType,
}: TSocialMediaSignInProps) {
  useEffect(() => {}, []);

  const responseGoogle = (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
    loginWithGoogle({ tokenId: (response as GoogleLoginResponse).tokenId });
  };

  const responseFacebookSuccess = (
    response: ReactFacebookLoginInfo | ReactFacebookFailureResponse
  ) => {
    loginWithFacebook({ access_token: (response as ReactFacebookLoginInfo).accessToken });
  };

  return (
    <footer>
      <p>or {authenticationType === 'register' ? 'Sign up' : 'Login'} with</p>
      <ul className={style['signup__options']}>
        <li>
          <GoogleLogin
            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}
            render={(renderProps) => (
              <img src={GoogleIcon} onClick={renderProps.onClick} alt="google" />
            )}
            onSuccess={responseGoogle}
            cookiePolicy={'single_host_origin'}
            autoLoad={false}
          />
        </li>
        <li>
          <img src={AppleIcon} alt="apple" />
        </li>
        <li>
          <FacebookLogin
            appId={process.env.REACT_APP_FACEBOOK_APP_ID}
            autoLoad={false}
            callback={responseFacebookSuccess}
            fields="name,email,picture"
            render={(renderProps: { onClick: () => void }) => (
              <img src={FacebookIcon} alt="facebook" onClick={renderProps.onClick} />
            )}
          />
        </li>
      </ul>
      {handleSuggestion(authenticationType)}
    </footer>
  );
}

const handleSuggestion = (authenticationType: 'login' | 'register') => {
  switch (authenticationType) {
    case 'register':
      return (
        <>
          <span>Have an Account?</span> <NavLink to="/login">Login</NavLink>
        </>
      );
    case 'login':
      return (
        <>
          <span>Don't have an Account?</span> <NavLink to="/sign-up">Sign up</NavLink>
        </>
      );

    default:
      break;
  }
};
