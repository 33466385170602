import style from './Messages.module.scss';
import Close from './assets/icons/close.svg';
import Search from './assets/icons/search.svg';
import Message from './subsections/Message/Message';
import useLockBodyScroll from 'src/hooks/General/useLockBodyScroll';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs';
import { Link } from 'react-router-dom';
import useChatNetworkRequest from '../../hooks/useChatNetworkRequest';

import { useLocation } from 'react-router-dom';
import { DrawerModal } from 'src/components/Modal';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'src';
import { TSingleChatWithLeadchef } from '../../Types';
dayjs.extend(LocalizedFormat);

const Messages = ({ isOpen, handleClose }: { isOpen: boolean; handleClose: () => void }) => {
    const [allconversations, setAllConversations] = useState<TSingleChatWithLeadchef[] | null>(
        null,
    );
    const [inputValue, setInputValues] = useState<string | null>(null);
    let location = useLocation();

    const { getConversationsWithLeadChef } = useChatNetworkRequest();

    useEffect(() => {
        if (isOpen) {
            getConversationsWithLeadChef();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    const { allChatsWthLeadChefResponse, getChatsWithLeadChef } = useChatNetworkRequest();

    const user = useAppSelector(state => state.auth.userData?.user);

    useEffect(() => {
        if (user?.role === 'lead_chef') {
            getChatsWithLeadChef();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.role]);

    useEffect(() => {
        if (allChatsWthLeadChefResponse) {
            setAllConversations(allChatsWthLeadChefResponse.data.results);
        }
    }, [allChatsWthLeadChefResponse]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValues(e.target.value);
    };

    useLockBodyScroll(isOpen);

    return (
        <DrawerModal isOpen={isOpen} onClickOutside={handleClose}>
            <div className={`${style.mainContainer} `}>
                <div className={style['header-container']}>
                    <div className={style['header']}>
                        <h4>Chats</h4>
                        <img
                            className={style['close-btn']}
                            onClick={handleClose}
                            src={Close}
                            alt="close button"
                        />
                    </div>
                    <div className={style['searchbar-container']}>
                        <input
                            type="text"
                            onChange={handleChange}
                            value={inputValue as string}
                            placeholder="Search users to start new conversation"
                        />
                        <img src={Search} alt="close button" />
                    </div>
                </div>
                {user?.role === 'lead_chef' ? (
                    allconversations
                        ?.filter(users => {
                            if (!inputValue) {
                                return users;
                            } else if (
                                inputValue &&
                                users.userA.name.toLowerCase().includes(inputValue.toLowerCase())
                            ) {
                                return users;
                            }
                            return null;
                        })
                        .map(chat => {
                            return (
                                <Link
                                    to={{
                                        pathname: `/messages/${chat._id}/chat`,
                                        state: { background: location, id: chat.userA },
                                    }}
                                >
                                    <Message
                                        username={chat.userA.name}
                                        profileImg={chat.userA.profileImageUrl}

                                        // message={chat.lastMessage.message}
                                    />
                                </Link>
                            );
                        })
                ) : (
                    <div>No conversations</div>
                )}
            </div>
        </DrawerModal>
    );
};

export default Messages;
