import React from "react";
import axios from "axios";
import styles from "./TriviaOption.module.css";
import { BASE_URL } from "../../../../utilities/endpoints";

interface OptionProps {
  option: string;
  questionID: string;
  optionNumber: number;
  candidateEmail: string;
  questionOptions: string;
}

const TriviaOption: React.FC<OptionProps> = ({
  option,
  questionID,
  optionNumber,
  candidateEmail,
  questionOptions,
}) => {
  const AnswerQuestion = async () => {
    await axios({
      method: "post",
      url: `${BASE_URL}/candidate/answer-question`,
      data: {
        questionId: questionID,
        answer: [optionNumber],
        email: `${candidateEmail}`,
      },
    });
  };
  const handleSubmitAnswer = () => {
    AnswerQuestion();
  };
  return (
    <div className={styles.option__wrapper}>
      <div className={styles.option__letter}>({questionOptions})</div>
      <div className={styles.option__value} onClick={handleSubmitAnswer}>
        {option}
      </div>
    </div>
  );
};

export default TriviaOption;
