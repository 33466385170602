import { useEffect } from 'react'

 function useAbort ()  {
    const onUnload = () => {
        const controller = new AbortController();
        controller.abort(); // abort the request
    }
    
    useEffect(() => {
        return () => {
          onUnload();   // abort the request function
        }
    }, []);
}


export default useAbort;