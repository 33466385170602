import { Drawer } from 'antd';
import { ReactNode } from 'react';
import useLockBodyScroll from 'src/hooks/General/useLockBodyScroll';
import useWindowSize from 'src/hooks/General/useWindowSize';

type IModalProps = {
  isOpen: boolean;
  onClickOutside?: () => void;
  children: ReactNode;
  width?: number;
};

const DrawerModal = ({ isOpen, onClickOutside, children }: IModalProps): null | JSX.Element => {
  useLockBodyScroll(isOpen);

  const { width } = useWindowSize();
  return (
    <Drawer
      placement={'right'}
      closable={false}
      width={width > 900 ? '550px' : '100%'}
      onClose={onClickOutside}
      visible={isOpen}
    >
      {children}
    </Drawer>
  );
};

export default DrawerModal;
