import React, { useEffect, useState } from "react";
import { FiSend } from "react-icons/fi";
import { GrClose } from "react-icons/gr";
import { MdAttachFile, MdMic, MdStop } from "react-icons/md";
import { useHistory, useLocation, useParams } from "react-router";
import { AudioVisualization } from "src/pages/Main/Classes/containers";
import useRecorder from "src/pages/Main/Classes/hooks/useRecorder";
import useChatNetworkRequest from "../../hooks/useChatNetworkRequest";
import style from "./index.module.scss";
import { v4 as uuidV4 } from "uuid";
import { useAppSelector } from "src";
import dayjs from "dayjs";
import LocalizedFormat from "dayjs";
import { DrawerModal } from "src/components/Modal";
import { Modal } from "antd";
import { usePusherContext } from "src/context/PusherContext/hooks/usePusherContext";
import { FaTimesCircle } from "react-icons/fa";
import  { TailSpin }  from "react-loader-spinner";
import { TSenderProps } from "../../Types";
import avatar from "../../../../../assets/images/default-avatar.webp"

dayjs.extend(LocalizedFormat);

type pusherObject = {
  message: string;
  imageUrls: string[];
  videoUrls: string[];
  audioUrls: string[];
  hasRead: boolean;
  deletedByUserA: boolean;
  deletedByUserB: boolean;
  _id: string;
  conversation: string;
  sender: string;
  receiver: string;
  createdAt: string;
  updatedAt: string;
};
const SingleMessageView = () => {
  type Tmessage = {
    id: string;
    message: string;
    audioRecording?: string;
    imagePreview?: string;
    imageUrls?: string[];
    audioUrls?: string[];
    createdAt?: string;
  };

  const { id } = useParams<{ id: string }>();
  let history = useHistory();

  // const {location} = useHistory<{
  //   messages: any
  // }>()

  //  console.log(id)
  // console.log(location?.state?.messages)
  const [messages, setMessages] = useState<Tmessage[]>([]);
  const [filePreview, setFilePreview] = useState<string | null>(null);
  const [fileData, setFileData] = useState<File | null>(null);
  const [inputValues, setInputValues] = useState("");
  const [isFullImageOpen, setIsFullImageOpen] = useState(-1);
  const [conversationId, setConversationId] = useState<string | null>(null);

  const user = useAppSelector((state) => state.auth.userData?.user);
  const { createConversation, data } = useChatNetworkRequest(
    user?.role !== "leadchef" ? "613f6ea9873b5554c24b7111" : ""
  );
  const {
    getConversationsWithLeadChef,
    conversationWthLeadChefResponse,
    isGettingConversations,
  } = useChatNetworkRequest();
  const { singleConversationResponse, getSingleConversation } =
    useChatNetworkRequest(id);
  const {
    recordString,
    handleRecord,
    resetRecordState,
    audioBlob,
    isRecording,
  } = useRecorder();

  const { state } = useLocation();

  const { PusherState } = usePusherContext();
  const { sendMessage } = useChatNetworkRequest(
    user?.role === "lead_chef" ? id : (conversationId as string)
  );

  // useEffect(()=>{
  //   console.log(singleConversationResponse)
  // },[singleConversationResponse])

  useEffect(() => {
    if (user?.role !== "lead_chef") {
      createConversation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data) {
      getConversationsWithLeadChef();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (conversationWthLeadChefResponse) {
      setMessages(
        conversationWthLeadChefResponse?.data.results?.map(
          ({ sender, message, audioUrls, imageUrls, createdAt }) => {
            return { id: sender._id, message, audioUrls, imageUrls, createdAt };
          }
        )
      );
      setConversationId(conversationWthLeadChefResponse?.data?.data?._id);
    }
    // else if (user?.role !== 'lead_chef' && !conversationWthLeadChefResponse) {
    //   createConversation();
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationWthLeadChefResponse]);

  useEffect(() => {
    if (user?.role === "lead_chef") {
      getSingleConversation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.role]);

  useEffect(() => {
    if (singleConversationResponse?.data?.results) {
      setMessages(
        singleConversationResponse?.data.results?.map(
          ({ sender, message, imageUrls, audioUrls, createdAt }) => {
            return {
              id: (sender as any)._id || (sender as string),
              message,
              imageUrls,
              audioUrls,
              createdAt,
            };
          }
        )
      );
    }
  }, [singleConversationResponse, user?.role]);

  const sendMessages = () => {
    setMessages([
      ...messages,
      {
        id: user?._id as string,
        message: inputValues,
        imagePreview: filePreview as string,
        audioRecording: recordString as string,
      } as Tmessage,
    ]);

    setInputValues("");
    resetRecordState();
    removeAttachment();

    /**
     * create formdata to send audio and image
     */

    const formData = new FormData();
    formData.append("message", inputValues);
    recordString && formData.append("audioAttachments", audioBlob as File);
    fileData && formData.append("imageAttachments", fileData as File);
    sendMessage(formData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const addAttachement = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files?.length > 0) {
      setFileData(e.target.files[0]);
      const reader = new FileReader();

      reader.onload = function (e: ProgressEvent<FileReader>) {
        setFilePreview(e.target?.result as string);
        // Render thumbnail.
      };

      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const removeAttachment = () => {
    setFileData(null);
    setFilePreview(null);
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputValues(e.target.value);
  };

  const handleKeydown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      sendMessages();
    }
  };

  let back = () => {
    history.goBack();
  };

  /**
   * scroll to bottom
   */

  const messagesEndRef = React.useRef<HTMLLIElement>();

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  });

  useEffect(() => {
    const channel = PusherState?.subscribe(
      (user?._id as string) ?? "613f6ea9873b5554c24b7111"
    );

    channel?.bind("lead-chef-chat-message", (data: pusherObject) => {
      if (data.sender !== user?._id) {
        setMessages((prevMessages) =>
          prevMessages.length > 0
            ? [
                ...prevMessages,
                {
                  id: data?.sender as string,
                  message: data.message,
                  imagePreview: data.imageUrls[0] as string,
                  audioRecording: data.audioUrls[0] as string,
                } as Tmessage,
              ]
            : [
                {
                  id: data?.sender as string,
                  message: data.message,
                  imagePreview: data.imageUrls[0] as string,
                  audioRecording: data.audioUrls[0] as string,
                } as Tmessage,
              ]
        );
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const displayLeadChefDetails = () => {
    return conversationWthLeadChefResponse?.data?.results[0]?.sender?.role ===
      "lead_chef"
      ? conversationWthLeadChefResponse?.data?.results[0]?.sender
      : conversationWthLeadChefResponse?.data?.results[0]?.receiver;
  };
  const displayUserDetails = () => {
    return singleConversationResponse?.data?.results[0]?.receiver?.role ===
      "lead_chef"
      ? singleConversationResponse?.data?.results[0]?.sender
      : singleConversationResponse?.data?.results[0]?.receiver;
  };

  return (
    <DrawerModal isOpen={!!id} onClickOutside={() => back()}>
      <div
        className={`${style["single-chat-view"]} 
        }`}
      >
        <header className={style["chat-header"]}>
          <div className={style["chat-info"]}>
            <div className={style["profile-img"]}>
              <img
                src={
                  user?.role === "lead_chef"
                    ? (displayUserDetails() as TSenderProps)?.profileImageUrl 
                    : displayLeadChefDetails()?.profileImageUrl || avatar

                  // @ts-ignore
                  // state.id?.profileImageUrl ?? displayLeadChefDetails()?.profileImageUrl
                }
                alt="avatar"
              />
            </div>
            <h4>
              {
                //@ts-expect-error
                state.id?.name ?? displayLeadChefDetails()?.name
              }
            </h4>
            
              
              {/* <TailSpin
                color="#5d41f1"
                height={20}
                width={20}
                visible={isGettingConversations}
              /> */}
              <TailSpin 
                  color="##5d41f1" 
                  height={20} 
                  width={20} 
                  visible={isGettingConversations}
                />
            
          </div>
          <GrClose onClick={back} />
        </header>

        <ul className={style["chat-area"]}>
          {messages &&
            messages?.length > 0 &&
            messages?.map((item, index) => (
              <li
                className={`${style["chat-area--item"]} ${
                  user?._id === item.id
                    ? style["chat-sender"]
                    : style["chat-reciever"]
                }`}
                key={index}
              >
                {((item.audioUrls && item.audioUrls[0]) ||
                  item.audioRecording) && (
                  <div className={style["waveform--wrapper"]}>
                    <AudioVisualization
                      progressColor={
                        user?._id === item.id ? "#00bcd4" : "#5d41f1"
                      }
                      url={
                        (item.audioUrls && item.audioUrls[0]) ||
                        (item.audioRecording as string)
                      }
                      container={`waveform-${uuidV4()}`}
                    />
                  </div>
                )}

                {(item.imagePreview ||
                  (item.imageUrls && item.imageUrls[0])) && (
                  <div
                    className={style["filepreview--wrapper"]}
                    onClick={() => setIsFullImageOpen(index)}
                  >
                    <img
                      src={
                        (item.imageUrls && item.imageUrls[0]) ||
                        item.imagePreview
                      }
                      alt=""
                    />
                  </div>
                )}
                {item.message && (
                  <p className={style["message"]}>{item.message}</p>
                )}
                <div className={style["message-date"]}>
                  {dayjs(item.createdAt).format("LLL")}
                </div>

                <Modal
                  centered
                  visible={isFullImageOpen === index}
                  width="fit-content"
                  // onOk={() => this.setModal2Visible(false)}
                  closeIcon={null}
                  closable={false}
                  footer={null}
                  onCancel={() => setIsFullImageOpen(-1)}
                >
                  <div className={style["modal-preview"]}>
                    <img
                      src={
                        (item.imageUrls && item.imageUrls[0]) ||
                        item.imagePreview
                      }
                      alt=""
                    />
                  </div>
                </Modal>
              </li>
            ))}
          <li ref={messagesEndRef as React.RefObject<HTMLLIElement>}></li>
        </ul>

        <div className={style["chat-input"]}>
          {!isRecording && (
            <>
              <label htmlFor="attach">
                <MdAttachFile className={style["icon-btn"]} />
              </label>
              <input
                type="file"
                onChange={addAttachement}
                className={style["input-file"]}
                name=""
                id="attach"
              />
            </>
          )}
          <textarea
            name="chat-input"
            placeholder={isRecording ? " Recording audio" : "Start typing"}
            onKeyDown={handleKeydown}
            value={inputValues || ""}
            onChange={handleChange}
            disabled={isRecording}
          />
          {!filePreview && !inputValues && (
            <span>
              {isRecording ? (
                <MdStop onClick={handleRecord} className={style["icon-btn"]} />
              ) : (
                <MdMic className={style["icon-btn"]} onClick={handleRecord} />
              )}
            </span>
          )}

          {!isRecording && (
            <FiSend onClick={sendMessages} className={style["icon-btn"]} />
          )}
        </div>
        {(filePreview || recordString) && (
          <div className={style["selected-file-previews"]}>
            {recordString && (
              <div className={style["waveform--wrapper-preview"]}>
                <div className={style["waveform-preview"]}>
                  <AudioVisualization
                    url={recordString as string}
                    container={`waveform-${uuidV4()}`}
                  />
                </div>

                <FaTimesCircle
                  className={style["remove-audio-icon"]}
                  onClick={resetRecordState}
                />
              </div>
            )}

            {fileData && (
              <span className={style["image-preview"]}>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={filePreview as string}
                >
                  {fileData?.name.substring(0, 10) + "..."}
                </a>

                <p className={style["remove-icon"]} onClick={removeAttachment}>
                  Remove
                </p>
              </span>
            )}
          </div>
        )}
      </div>
    </DrawerModal>
  );
};

export default React.memo(SingleMessageView);
