import React, { ReactNode, useEffect, useState } from 'react';

import { createContext } from 'react';

import Pusher from 'pusher-js';

export const PusherContext = createContext<{ PusherState: Pusher | null } | undefined>(undefined);

//context setup
export default function PusherContextProvider({ children }: { children: ReactNode }) {
  const [PusherState, setPusherState] = useState<Pusher | null>(null);

  useEffect(() => {
    const pusher = new Pusher(`${process.env.REACT_APP_PUSHER_APP_KEY}`, {
      cluster: `${process.env.REACT_APP_PUSHER_APP_CLUSTER}`,
    });
    setPusherState(pusher);
  }, []);

  return <PusherContext.Provider value={{ PusherState }}>{children}</PusherContext.Provider>;
}

export function useCartUpdate() {
  const context = React.useContext(PusherContext);

  if (context === undefined) {
    throw new Error('useCartUpdate must be used within a PusherContextProvider');
  }

  return context;
}
