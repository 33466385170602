import MaskedAuthImg from '../assets/images/auth-image.png';
import AdminBgImage from '../assets/images/AdminBG-image.jpg';
import style from '../authLayout.module.scss';
import { NavLink } from 'react-router-dom';

export default function useAdminType() {
  const handleAdminType = (adminType: string) => {
    switch (adminType) {
      case 'admin':
        return <AdminAuth />;
      case 'regular':
        return <RegularAuth />;
      case 'lead-chef':
        return <AdminAuth />;
      default:
        return <RegularAuth />;
    }
  };
  return { handleAdminType };
}

//Name needs to be updated to reflect
const RegularAuth = () => {
  return (
    <>
      <header>
        <NavLink to="/">
          <h3>Ify’s Kitchen</h3>
        </NavLink>
      </header>
      <div>
        <img src={MaskedAuthImg} alt="decorative" />
      </div>
    </>
  );
};

const AdminAuth = () => {
  return (
    <>
      <header className={style['admin__auth--header']}>
        <h3>
          Cook, Eat, <span>Repeat.</span>
        </h3>
      </header>
      <div className={style['overlay']}></div>
      <div className={style['admin__auth--image']}>
        <img src={AdminBgImage} alt="decorative" />
      </div>
    </>
  );
};
