import React, { ReactNode, useEffect } from 'react';
import { useReducer } from 'react';
import { createContext } from 'react';
import { useHistory } from 'react-router-dom';
import { SubscriptionPlans } from 'src/components/SubscriptionPlans';
import useAuth from 'src/hooks/General/useAuth';

type AuthModalState = {
    showAuthModal: boolean;
};

enum AuthModalType {
    SHOW_MODAL = 'SHOW_MODAL',
    CLOSE_MODAL = 'CLOSE_MODAL',
}

export const AuthModalContext = createContext<
    { state: AuthModalState; dispatch: React.Dispatch<AuthModalActionType> } | undefined
>(undefined);

// An interface for our actions
type AuthModalActionType = {
    type: 'SHOW_MODAL' | 'CLOSE_MODAL';
};

const initialAuthModalState: AuthModalState = {
    showAuthModal: false,
};

//usereducer function
function reducer(state = initialAuthModalState, action: AuthModalActionType): AuthModalState {
    switch (action.type) {
        case AuthModalType.SHOW_MODAL:
            return {
                ...state,
                showAuthModal: true,
            };
        case AuthModalType.CLOSE_MODAL:
            return {
                ...state,
                showAuthModal: false,
            };
        default:
            throw new Error('Unhandled Modal Instruction');
    }
}

export function useAuthModalContext() {
    const context = React.useContext(AuthModalContext);

    if (context === undefined) {
        throw new Error('AuthModalContext must be used within a AuthModalCOntextProvider');
    }

    return context;
}

//context setup
export default function AuthModalProvider({ children }: { children: ReactNode }) {
    const [state, dispatch] = useReducer(reducer, {
        showAuthModal: false,
    });
    const { userData, isLoggedIn } = useAuth();
    const history = useHistory();

    const values = { state, dispatch };

    useEffect(() => {
        /**
         * Axios instance to check users subscription and show the subscription plans if the user is not subscribed
         * We check if the user is on the leadchef route and if the user is not logged in
         * if the user is on the leadchef or admin route, we do nothing else we continue downwards to check if the user is logged in
         * or if the user has an active sub or is unauthorized to access that route
         */

        const path = history.location.pathname.toLowerCase();
        const isAdminOrLeadChefRoute = path.includes('lead-chef') || path.includes('admin');

        if (!isAdminOrLeadChefRoute && isLoggedIn) {
            if (!userData?.user.activeSub) {
                dispatch({
                    type: 'SHOW_MODAL',
                });
            }
        } else {
            dispatch({ type: 'CLOSE_MODAL' });
        }

        if (isLoggedIn) {
            dispatch({ type: 'CLOSE_MODAL' });
        }
    }, [history.location.pathname, isLoggedIn, userData]);

    return (
        <AuthModalContext.Provider value={values}>
            <SubscriptionPlans
                isOpen={state.showAuthModal}
                onClose={
                    userData?.user?.activeSub ? () => dispatch({ type: 'CLOSE_MODAL' }) : () => {}
                }
            />
            {children}
        </AuthModalContext.Provider>
    );
}
