import { LeadChefPrivateRoute } from 'src/components/ProtectedRoutes/LeadChef';
import React from 'react';

import { IRoute } from '../types/index';
import SlideInFormContext from 'src/pages/LeadChef/Videos/context/SlideInformContext';
import { Switch, useLocation } from 'react-router';
const Webinar = React.lazy(() => import('src/pages/Webinar'));
const AdminRoom = React.lazy(() => import('src/pages/LeadChef/AdminRoom'));
const Classrooms = React.lazy(() => import('src/pages/LeadChef/Classrooms'));
const LeadChefDashboard = React.lazy(() => import('src/pages/LeadChef/Dashboard'));
const LeadChefRecipes = React.lazy(() => import('src/pages/LeadChef/Recipes'));
const LeadChefShop = React.lazy(() => import('src/pages/LeadChef/Shop'));
const VideoContent = React.lazy(() => import('src/pages/LeadChef/Videos'));
const ViewSingleVideoLeadchef = React.lazy(
    () => import('src/pages/LeadChef/Videos/components/ViewSingleVideo'),
);
const ViewSingleRecipe = React.lazy(
    () => import('src/pages/LeadChef/Recipes/subroute/view-single-recipe'),
);

type locationState = {
    state: {
        background: string;
    };
};
const LeadchefRoutes = ({ isLoggedIn, userData }: IRoute) => {
    let location = useLocation();

    let background = location.state && (location as locationState).state.background;

    return (
        <>
            <Switch
                //@ts-ignore
                location={background || location}
            >
                {/* Dasboard */}
                <LeadChefPrivateRoute
                    exact
                    path="/lead-chef/dashboard"
                    component={LeadChefDashboard}
                    isAuth={isLoggedIn}
                    isLeadChef={userData?.user.role === 'lead_chef'}
                />

                {/* Recipes */}
                <LeadChefPrivateRoute
                    exact
                    path={['/lead-chef/recipes/', '/lead-chef/recipes/:recipeId/edit']}
                    component={LeadChefRecipes}
                    isAuth={isLoggedIn}
                    isLeadChef={userData?.user.role === 'lead_chef'}
                />
                <LeadChefPrivateRoute
                    exact
                    path="/lead-chef/recipes/:recipeId/view"
                    component={ViewSingleRecipe}
                    isAuth={isLoggedIn}
                    isLeadChef={userData?.user.role === 'lead_chef'}
                />

                {/* === Classrooms === */}
                <LeadChefPrivateRoute
                    exact
                    path={['/lead-chef/classrooms', '/lead-chef/classrooms/:id/edit']}
                    component={Classrooms}
                    isAuth={isLoggedIn}
                    isLeadChef={userData?.user.role === 'lead_chef'}
                />

                {/* Live Webinar */}
                <LeadChefPrivateRoute
                    exact
                    path="/lead-chef/webinar"
                    component={Webinar}
                    isAuth={isLoggedIn}
                    isLeadChef={userData?.user.role === 'lead_chef'}
                />
                {/* Shop Route */}
                <LeadChefPrivateRoute
                    exact
                    path="/lead-chef/shop"
                    component={LeadChefShop}
                    isAuth={isLoggedIn}
                    isLeadChef={userData?.user.role === 'lead_chef'}
                />

                {/* Administrators */}
                <LeadChefPrivateRoute
                    exact
                    path="/lead-chef/administrators"
                    component={AdminRoom}
                    isAuth={isLoggedIn}
                    isLeadChef={userData?.user.role === 'lead_chef'}
                />

                {/* Videos */}
                <SlideInFormContext>
                    <LeadChefPrivateRoute
                        exact
                        path="/lead-chef/videos/:id"
                        component={ViewSingleVideoLeadchef}
                        isAuth={isLoggedIn}
                        isLeadChef={userData?.user.role === 'lead_chef'}
                    />
                    <LeadChefPrivateRoute
                        exact
                        path={['/lead-chef/videos']}
                        component={VideoContent}
                        isAuth={isLoggedIn}
                        isLeadChef={userData?.user.role === 'lead_chef'}
                    />
                    <LeadChefPrivateRoute
                        exact
                        path={['/lead-chef/video/:id/edit', '/lead-chef/video/:id/draft']}
                        component={VideoContent}
                        isAuth={isLoggedIn}
                        isLeadChef={userData?.user.role === 'lead_chef'}
                    />
                </SlideInFormContext>
            </Switch>
            {/* {background && <Route path="/messages/:id/chat" children={<SingleMessageView />} />} */}
        </>
    );
};

export default LeadchefRoutes;
