import React from 'react';
import { Switch, useLocation } from 'react-router-dom';
import { AdminPrivateRoute } from 'src/components/ProtectedRoutes/Admin';
// import TriviaQuestionAdmin from 'src/pages/Admin/TriviaQuestion';
import { IRoute } from '../types/index';
const AdminDashboard = React.lazy(() => import('src/pages/Admin/Dashboard/admin-dashboard'));
const Complaints = React.lazy(() => import('src/pages/Admin/Complaints'));
const Chat = React.lazy(() => import('src/pages/Admin/Complaints/components/Chat'));
const TriviaAdmin = React.lazy(() => import('src/pages/Admin/Trivia'));
const TriviaQuestionAdmin = React.lazy(() => import('src/pages/Admin/TriviaQuestion'));
const TriviaSettings = React.lazy(() => import('src/pages/Admin/TriviaSettings'));
const QuestionForm = React.lazy(
  () => import('src/pages/Admin/TriviaSettings/components/QuestionForm')
);
const UsersAdmin = React.lazy(() => import('src/pages/Admin/Users/admin-subscribers'));

const AdminRoutes = ({ isLoggedIn, userData }: IRoute) => {
  let location = useLocation<{ chatScreen: '' }>();

  let chat = location.state && location.state.chatScreen;

  return (
    <div>
      <Switch location={chat || location}>
        <AdminPrivateRoute
          exact
          path="/admin/dashboard"
          component={AdminDashboard}
          isAuth={isLoggedIn}
          isAdmin={userData?.user.role !== 'regular'}
        />
        <AdminPrivateRoute
          exact
          path="/admin/trivia"
          component={TriviaAdmin}
          isAuth={isLoggedIn}
          isAdmin={userData?.user.role !== 'regular'}
        />

        {/* View or create a question set */}
        <AdminPrivateRoute
          exact
          path="/admin/trivia/setup"
          component={TriviaSettings}
          isAuth={isLoggedIn}
          isAdmin={userData?.user.role !== 'regular'}
        />
        {/* View a single question set */}
        <AdminPrivateRoute
          exact
          path="/admin/trivia/settings/:id/question-set/:name"
          component={TriviaQuestionAdmin}
          isAuth={isLoggedIn}
          isAdmin={userData?.user.role !== 'regular'}
        />

        {/* Edit a question in a questions set, id = questionId */}
        <AdminPrivateRoute
          exact
          path="/admin/trivia/question-set/:id/question/:actionType"
          component={QuestionForm}
          isAuth={isLoggedIn}
          isAdmin={userData?.user.role !== 'regular'}
        />
        {/* create a question in a questions set, id = questionId */}
        <AdminPrivateRoute
          exact
          path="/admin/trivia/question-set/:id/question/:actionType"
          component={QuestionForm}
          isAuth={isLoggedIn}
          isAdmin={userData?.user.role !== 'regular'}
        />
        <AdminPrivateRoute
          exact
          path="/trivia/settings/draw/:id"
          component={TriviaAdmin}
          isAuth={isLoggedIn}
          isAdmin={userData?.user.role !== 'regular'}
        />
        <AdminPrivateRoute
          exact
          path="/admin/messages"
          component={Complaints}
          isAuth={isLoggedIn}
          isAdmin={userData?.user.role !== 'regular'}
        />
        <AdminPrivateRoute
          exact
          path="/admin/messages/:id"
          component={Chat}
          isAuth={isLoggedIn}
          isAdmin={userData?.user.role !== 'regular'}
        />
        <AdminPrivateRoute
          exact
          path="/admin/users"
          component={UsersAdmin}
          isAuth={isLoggedIn}
          isAdmin={userData?.user.role !== 'regular'}
        />
      </Switch>
      {chat && (
        <AdminPrivateRoute
          path="/admin/messages/:id"
          component={Chat}
          isAuth={isLoggedIn}
          isAdmin={userData?.user.role !== 'regular'}
        />
      )}
    </div>
  );
};

export default AdminRoutes;
