import React from 'react';
import styles from './GetStarted.module.scss';
// import Ify from "./assets/images/Ify.svg";
// import Ifa from "./assets/images/Rectangle 597.png";
import one from './assets/images/one.svg';
import two from './assets/images/two.svg';
import three from './assets/images/three.svg';
import zero_one from './assets/images/zero_one.svg';
import zero_two from './assets/images/zero_two.svg';
import zero_three from './assets/images/zero_three.svg';
import ellipsis from './assets/images/ellipsis.svg';

function GetStarted() {
    return (
        <div className={styles.container}>
            <div className={styles.ellipsis_image}>
                <img src={ellipsis} alt="" />
            </div>
            <div className={styles.wrapper}>
                {/* <div className={`${styles.mobile_first_text} ${styles.first_text}`}>
                    <p>COMING SOON</p>
                </div> */}
                <h1>Get started in 3 easy steps</h1>
                <div className={styles.hero_image_container}>
                    <div className={styles.hero_image}></div>
                </div>
                <div className={styles.hero_text}>
                    {/* <div className={`${styles.desktop_first_text} ${styles.first_text}`}>
                        <p >COMING SOON</p>
                    </div> */}
                    <h1>Get started in 3 easy steps</h1>
                    <div className={styles.steps}>
                        <div className={styles.desktop_image}>
                            <img src={one} alt="" />
                        </div>
                        <div className={styles.mobile_image}>
                            <img src={zero_one} alt="" />
                        </div>
                        <div className={styles.content}>
                            <h3>Create an Account</h3>
                            <p>
                                Sign up for free on our website and set up your account with your
                                details
                            </p>
                        </div>
                    </div>
                    <div className={styles.steps}>
                        <div className={styles.desktop_image}>
                            <img src={two} alt="" />
                        </div>

                        <div className={styles.mobile_image}>
                            <img src={zero_two} alt="" />
                        </div>
                        <div className={styles.content}>
                            <h3>Subscribe</h3>
                            <p>
                                Subscribe to any plan of your choice as either a regular or premium
                                member
                            </p>
                        </div>
                    </div>
                    <div className={styles.steps}>
                        <div className={styles.desktop_image}>
                            <img src={three} alt="" />
                        </div>
                        <div className={styles.mobile_image}>
                            <img src={zero_three} alt="" />
                        </div>
                        <div className={styles.content}>
                            <h3>Get cooking</h3>
                            <p>
                                Dive into the curated list of videos and resources to make sure your
                                meals bang
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GetStarted;
