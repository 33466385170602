import Features from "../../components/Features/Features";
import Nav from "../../components/Nav/Nav";
import Hero from "../../components/Header/Header";
import FAQS from "../../components/FAQS/FAQS";
import GetStarted from "../../components/GetStarted/GetStarted";

import FeaturedVideos from "../../components/FeaturedVideos/FeaturedVideos";
// import CTA from "../../components/CTA/CTA";
import Footer from "../../components/Footer/Footer";
import { LandingPagePlans } from "src/components/Subscriptions";
// import Select from "../../components/Select/Select";
// import Input from "../../components/Input/Input";
// import Icon from "../../assets/icons/dropdown.svg";

function Landing() {
  return (
    <div>
      <Nav />
      <Hero />
      <Features />
      <GetStarted />
      <LandingPagePlans />
      <FeaturedVideos />
      <FAQS />
      {/* <CTA /> */}
      <Footer />
    </div>
  );
}

export default Landing;
