import useNetworkRequest from 'src/hooks/General/useNetworkRequest';
import { TSingleComplaintReplies } from 'src/pages/Admin/Complaints/TypeResponses';
import { BASE_URL } from 'src/utilities/endpoints';

export type TCreateComplaint = {
  status: string;
  message: string;
  data: {
    isResolved: boolean;
    status: string;
    isDeleted: boolean;
    _id: string;
    title: string;
    description: string;
    userId: string;
    createdAt: string;
    updatedAt: string;
  };
};

export default function useComplaintsNetworkRequest(complaintId?: string) {
  const [{ data: response, isLoading: isSendingReply }, sendReply] = useNetworkRequest<
    unknown,
    TCreateComplaint
  >(`${BASE_URL}/complaint/${complaintId}/reply`, {
    method: 'POST',
  });

  /*=============================================
    =            create complaint           =
    =============================================*/
  const [
    { data: createComplaintResponse, isLoading: isCreatingComplaint, error: createComplaintError },
    createComplaint,
  ] = useNetworkRequest<unknown, TCreateComplaint>(`${BASE_URL}/complaint/`, {
    method: 'POST',
  });

  /*=============================================
    =            Update complaint  status          =
    =============================================*/
  const [
    { data: updateResponse, isLoading: isUpdatingResponse, error },
    updateComplaintStatus,
    resetUpdateResponse,
  ] = useNetworkRequest<unknown, TCreateComplaint>(
    `${BASE_URL}/complaint/${complaintId}/update-status`,
    {
      method: 'PATCH',
    }
  );

  /*=============================================
  =            GET Complaint activity            =
  =============================================*/

  const [
    { data: activityResponse, isLoading: isLoadingActivity, error: isLoadingActivityError },
    FetchReplies,
  ] = useNetworkRequest<unknown, TSingleComplaintReplies>(
    `${BASE_URL}/complaint/${complaintId}/activity?eventType=reply`,
    {
      method: 'GET',
    }
  );

  return {
    response,
    isSendingReply,
    sendReply,

    /*=============================================
=            create complaint          =
=============================================*/

    createComplaintResponse,
    isCreatingComplaint,
    createComplaint,
    createComplaintError,

    /*=============================================
    =            Update complaint status            =
    =============================================*/

    updateResponse,
    isUpdatingResponse,
    updateComplaintStatus,
    error,
    resetUpdateResponse,

    /*=============================================
    =            GET Complaint activity            =
    =============================================*/
    activityResponse,
    FetchReplies,
    isLoadingActivity,
    isLoadingActivityError,
  };
}
