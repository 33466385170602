/* eslint-disable react/display-name */
import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useAppDispatch } from 'src';
import useAbort from 'src/hooks/General/useAbort';
import { clearUserData, setLoginState } from 'src/redux/Slices/authSlice';

export interface IprotectedRoutes extends RouteProps {
  isAuth: boolean;
}

export const PrivateRoute = React.memo(
  ({ component: Component, isAuth, ...rest }: IprotectedRoutes): JSX.Element | null => {
    const dispatch = useAppDispatch();
    
    useAbort() // this aborts all requests when the component unmounts
    
    if (!Component) return null;

    const logout = () => {
      localStorage.removeItem('token');
      dispatch(clearUserData);
      dispatch(setLoginState(false));
    };

    return (
      <Route
        {...rest}
        render={(props) =>
          isAuth ? (
            <Component {...props} />
          ) : (
            <>
              {logout()}
              <Redirect
                to={{
                  pathname: '/login',
                  state: { from: props.location },
                }}
              />
            </>
          )
        }
      />
    );
  }
);
