import React, { useState } from "react";
import LogoIconMobile from "../Nav/assets/images/LogoIconMobile.svg";
import {Link } from "react-router-dom";
import "./Nav.scss";
import { useHistory, useLocation } from "react-router-dom";

function Nav() {
  const [toggle, setToggle] = useState(false);
  let history = useHistory();
  let location = useLocation();

  function scrollToSubscriptions(){
    if (location.pathname === "/")
    {
      let element = document.getElementById('subscription-section');
      let elementOffset = element.getBoundingClientRect().top;
      window.scrollBy(0, elementOffset);
      setToggle(false);
    }
    else{
      history.push("/");
      setTimeout(function(){ 
        let element = document.getElementById('subscription-section');
      let elementOffset = element.getBoundingClientRect().top;
      window.scrollBy(0, elementOffset);
      setToggle(false);
      }, 800);
    }
  }

  function scrollToFAQ(){
    if (location.pathname === "/")
    {
      let element = document.getElementById('FAQ-section');
      let elementOffset = element.getBoundingClientRect().top;
      window.scrollBy(0, elementOffset);
      setToggle(false);
    }
    else{
      history.push("/");
      setTimeout(function(){
      let element = document.getElementById('FAQ-section');
      let elementOffset = element.getBoundingClientRect().top;
      window.scrollBy(0, elementOffset);
      setToggle(false);
    }, 800);
    }
  }

  return (
      <div id="nav" className="header">
        <div
          className={
            toggle
              ? "mobile-navbar mobile-navbar-open"
              : "mobile-navbar mobile-navbar-close"
          }
        >
          <div className="mobile-header">
            <div className="logo mobile-logo">Ify’s Kitchen</div>
            <div className="mobile-close-btn" onClick={() => setToggle(false)}>
              <i className="fas fa-times-circle"></i>
            </div>
          </div>
          <div className="link mobile-link">
            <Link to="/AboutUs">About Us</Link>
          </div>
          <div onClick={scrollToSubscriptions} className="link mobile-link">
            <Link to="">Subscription</Link>
          </div>
          <div onClick={scrollToFAQ} className="link mobile-link">
            <Link to=""> FAQ's</Link>
          </div>
          {/* <div className="link mobile-link">
            <Link to="/Contact"> Contact</Link>
          </div> */}
          <div className="get-started mobile-get-started">
            <Link to="/sign-up">Sign up</Link>
          </div>
        </div>

        <div className="logo">
          <Link to="/"> Ify’s Kitchen</Link>
        </div>

        <div className="links">
          <div className="link">
            <Link to="/AboutUs">About Us</Link>
          </div>
          <div onClick={scrollToSubscriptions} className="link">
            <Link to="">Subscription</Link>
          </div>
          <div onClick={scrollToFAQ} className="link">
            <Link to=""> FAQ's</Link>
          </div>
          {/* <div className="link">
            <Link to="/Contact"> Contact</Link>
          </div> */}
          <div className="get-started">
            <Link to="/sign-up">Sign up</Link>
          </div>
        </div>

        <div className="hamburger" onClick={() => setToggle(true)}>
          <img src={LogoIconMobile} alt="" />
        </div>
      </div>
  );
}

export default Nav;
