import style from './sign-in.module.scss';

import { useAcceptInvite } from './Login/hooks/useAcceptInvite';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { AuthLayout } from '../../components/Layout/Auth';
import FormikControls from '../../components/FormikControls';
import { Button } from '../../components/Button';

const AcceptInvite = (): JSX.Element => {
  const { acceptInvite, isLoading, reset, requestError } = useAcceptInvite();

  const inviteSchema = Yup.object().shape({
    firstName: Yup.string().min(3).required('Required'),
    lastName: Yup.string().min(3).required('Required'),
    password: Yup.string().min(8).required('Required'),
    phone: Yup.string().min(11).max(11).required('Required'),
    confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
  });

  return (
    <AuthLayout userType="admin">
      <header className={style['header']}>
        <h1>Admin Sign up</h1>

        {requestError && <p className={style['error']}>{requestError.response?.data.message}</p>}
      </header>

      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
          password: '',
          confirmPassword: '',
          phone: '',
        }}
        onSubmit={(values) => {
          reset();
          acceptInvite(values);
        }}
        validationSchema={inviteSchema}
      >
        <Form className={style['form']}>
          <div className={style['form--group']}>
            <div className={style['custom__field']}>
              <label htmlFor="">First Name</label>
              <FormikControls control="input" name="firstName" placeholder="Alison" />
            </div>
            <div className={style['custom__field']}>
              <label htmlFor="">Last Name</label>
              <FormikControls control="input" name="lastName" placeholder="Eyo" />
            </div>
          </div>

          <div className={style['custom__field']}>
            <label htmlFor="">Phone Number</label>
            <FormikControls
              control="input"
              name="phone"
              type="text"
              placeholder="eg. 081234556790"
            />
          </div>
          <div className={style['custom__field']}>
            <label htmlFor="">Password</label>
            <FormikControls
              control="input"
              name="password"
              type="password"
              label=""
              placeholder="Enter a secure password"
            />
          </div>
          <div className={style['custom__field']}>
            <label htmlFor="">Confirm Password</label>
            <FormikControls
              control="input"
              name="confirmPassword"
              type="password"
              label=""
              placeholder="Confirm secure password"
            />
          </div>

          <Button type="submit" variant="secondary" disabled={isLoading}>
            {isLoading ? 'Please Wait...' : 'Submit Details'}
          </Button>
        </Form>
      </Formik>
    </AuthLayout>
  );
};

export default AcceptInvite;
