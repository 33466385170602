import { useHistory } from "react-router-dom";
import style from "./index.module.scss";
import { Button } from "../Button";
import SuccessIcon from "../../components/PreOrderModal/assets/images/SuccessIcon.svg";
import ErrorIcon from "../../components/PreOrderModal/assets/images/ErrorIcon.svg";
import { TPreorder } from "./types";

function PreOrderModal({ status, error }: TPreorder) {
  const history = useHistory();

  const handleClick = () => {
    if (status === "success") {
      history.push("/");
    } else {
      history.goBack();
    }
  };

  return (
    <div className={style.main_container}>
      <div className={style.main}>
        <img src={status === "success" ? SuccessIcon : ErrorIcon} alt="" />
        <h1>{status === "success" ? " successful" : " failed"}</h1>
        <p>
          {status === "success"
            ? "Let's get it"
            : error?.response?.data.message}
        </p>
        <Button onClick={handleClick}>
          {status === "success" ? "Continue" : "Try again"}
        </Button>
      </div>
    </div>
  );
}

export default PreOrderModal;
