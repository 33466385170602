import React, {useRef} from "react";
// import left-arrow from "./assets/images/left-arrow.png"
import styles from "./featuredVideos.module.scss";
import leftArrow  from "./assets/images/left-arrow.png"
import rightArrow from "./assets/images/right-arrow.png"

const FeaturedVideos = () => {

    const videoContainer = useRef()
    const handleVideoScrollLeft = () => {
        videoContainer.current.scrollLeft -= 470
    }
    const handleVideoScrollRight = () => {
        videoContainer.current.scrollLeft += 470
    }

    const data = [
        {
            video: 'https://www.youtube.com/embed/hxEkuZ4yKRU',
            title: 'How to make yamarita - Nigerian Yam recipe - Holiday series'
        },
        {
            video: 'https://www.youtube.com/embed/F04wO5RXAmI',
            title: 'How to make the perfect Nigerian party jollof rice'
        },
        {
            video: 'https://www.youtube.com/embed/vg91HRba-eo',
            title: 'how to make a delicious egg stew for yam'
        },
        {
            video: 'https://www.youtube.com/embed/Vo4zmfqvKR0',
            title: 'how to cook delicious egusi ijebu soup'
        },
        {
            video: 'https://www.youtube.com/embed/cKuafynBj7Y',
            title: 'how to make a classic, creamy and delicious coleslaw recipe plus the perfect oven grilled chicken'
        },
        {
            video: 'https://www.youtube.com/embed/gWXbcd7jfjI',
            title: 'how to make nigerian vegetable fried rice / fried rice recipe'
        },
        {
            video: 'https://www.youtube.com/embed/l6KKrwDxl4s',
            title: 'delicious!!! this would be the easiest and tastiest rice dish you have ever made'
        },
        {
            video: 'https://www.youtube.com/embed/egHS0xuyFws',
            title: 'how to make rich and delicious oha soup'
        }
    ]
   
    return(
        <div>
            <div className={styles.top_section_container}>
                <div className={styles.top_section}>
                    <div className={styles.heading}>
                        <p className={styles.title}>Featured Videos</p>
                        <p className={styles.subTitle}>A sneak peek into the delightful recipes on Ify’s Menu</p>
                    </div>
                    <div className={styles.arrows}>
                        <img className={styles.left_arrow} src={leftArrow} onClick={handleVideoScrollLeft}  alt=""/>
                        <img src={rightArrow} onClick={handleVideoScrollRight}  alt=""/>
                    </div>
                </div>
            </div>
            <div className={styles.video_section} ref={videoContainer}>    
           
                {data.map((video, index) => (
                    <div>
                        <iframe className={styles.firstVideo} src={video.video} title={video.title} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" height="250" allowFullScreen></iframe>
                        <p className={styles.video__title}>{video.title}</p>
                    </div>
                ))}
            </div>  
        </div>        
    )
}

export default FeaturedVideos;