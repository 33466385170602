import React, { ReactNode } from 'react';
import { useReducer } from 'react';
import { createContext } from 'react';
import {
  NotificationAction,
  NotificationActionType,
  NotificationState,
  TSingleNotificationItem,
} from '../types';
import SuccessIcon from './assets/icons/success-icon.svg';
import ErrorIcon from './assets/icons/error-icon.svg';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import style from './index.module.scss';
import { v1 as uuidv1 } from 'uuid';
import LoadingScreen from './components/LoadingScreen/loadingScreen';
import { useEffect } from 'react';

export const NotificationContext = createContext<
  { state: NotificationState; dispatch: React.Dispatch<NotificationAction> } | undefined
>(undefined);

const initialNotificationState: NotificationState = {
  showNotification: false,
  message: [] as TSingleNotificationItem[],
  isLoading: true,
};



//usereducer function
function reducer(state = initialNotificationState, action: NotificationAction): NotificationState {
  switch (action.type) {
    case NotificationActionType.SHOW_NOTIFICATION:
      return {
        ...state,
        showNotification: true,
        isLoading: false,
        message: addMessage(state, action.payload as TSingleNotificationItem),
      };
    case NotificationActionType.CLEAR_NOTIFICATION:
      return {
        ...state,
        isLoading: false,
        message: clearNotification() as TSingleNotificationItem[],
      };

    case NotificationActionType.HIDE_NOTIFICATION:
      return state;
    case NotificationActionType.ISLOADING:
      return {
        ...state,
        isLoading: action.payload as boolean,
      };

    default:
      throw new Error('Unhandled Notification Instruction');
  }
}

const addMessage = (state: NotificationState, payload: TSingleNotificationItem) => {
  const payloadData = state.message;
  if (state.message.length > 0) {
    return [payload, ...payloadData];
  } else return [payload];
};

const clearNotification = () => {
  return [];
};

//context setup
export default function NotificationContextProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(reducer, {
    showNotification: false,
    message: [],
    isLoading: false,
  });
  const values = { state, dispatch };

  useEffect(() => {
    if (state.message && state.message.length > 0) {
      setTimeout(() => dispatch({ type: NotificationActionType.CLEAR_NOTIFICATION }), 3500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.message?.length]);

  return (
    <NotificationContext.Provider value={values}>
      {state.isLoading && <LoadingScreen isOpen={state.isLoading} />}

      {
        <TransitionGroup component="ul" className={style['notification__wrapper']}>
          {state.message.map((item) => (
            <CSSTransition
              timeout={3000}
              classNames={{
                enter: style['item-enter'],
                exit: style['item-exit'],
                enterActive: style['item-enter-active'],
                exitActive: style['item-exit-active'],
              }}
              unmountOnExit
            >
              <li key={uuidv1()}>
                <Notification message={item.message} notificationType={item.type} />
              </li>
            </CSSTransition>
          ))}
        </TransitionGroup>
      }

      {children}
    </NotificationContext.Provider>
  );
}

const Notification = ({
  notificationType,
  message,
}: {
  notificationType: 'error' | 'success';
  message: string;
}) => {
  switch (notificationType) {
    case 'error':
      return (
        <div className={style['error__notification']}>
          <img src={ErrorIcon} alt="" />
          <div className={style['message']}>
            <h4>Error</h4>
            <p>{message}</p>
          </div>
        </div>
      );
    case 'success':
      return (
        <div className={style['success__notification']}>
          <img src={SuccessIcon} alt="" />
          <div className={style['message']}>
            <h4>Success</h4>
            <p>{message}</p>
          </div>
        </div>
      );

    default:
      return null;
  }
};
