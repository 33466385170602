import React, { useState } from 'react';
import { ErrorMessage, Field, useFormikContext } from 'formik';
import style from './index.module.scss';
import Hide from './assets/icons/hide.png';
import View from './assets/icons/view.png';
import { Input } from 'antd';

export default function FormikControls(props) {
  const { control, ...rest } = props;
  switch (control) {
    case 'input':
      return <InputProp {...rest} />;
    case 'select':
      return <Select {...rest} />;
    case 'textarea':
      return <Textarea {...rest} />;

    default:
      return null;
  }
}

const InputProp = ({ name, children, ...rest }) => {
  const { errors, touched, field } = useFormikContext();
  const [showPassword, setShowPassword] = useState(false);

  const toggleViewHandler = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className={`${style['form--item']} ${rest.className}`}>
      {rest.type === 'password' && (
        <img
          onClick={toggleViewHandler}
          className={style['password-eye']}
          src={showPassword ? View : Hide}
          alt=""
        />
      )}

      {children}
      <Field
        as={Input}
        size={rest.size || 'large'}
        name={name || 'price'}
        {...rest}
        {...field}
        type={showPassword ? 'text' : rest.type}
        className={errors[name] && touched[name] ? 'input--error' : style['input__field']}
      />
      <ErrorMessage name={name} component="div" className={style['error--text']} />
    </div>
  );
};

const Select = (props) => {
  const { children, name, className, ...rest } = props;
  const { errors, touched } = useFormikContext();

  return (
    <div className={style['form--item']}>
      <Field
        as="select"
        name={name}
        {...rest}
        // style={{ fontWeight:'bold', color:"#000"}}
        className={errors[name] && touched[name] ? 'input--error' : null}
      >
        {children}
      </Field>
      <ErrorMessage name={name} component="div" className={style['error--text']} />
    </div>
  );
};

const Textarea = (props) => {
  const { children, name, className, ...rest } = props;
  const { errors, touched } = useFormikContext();

  return (
    <div className={`${style['form--control']} ${className ? className : ''}`}>
      <Field
        name={name}
        as="textarea"
        className={errors[name] && touched[name] ? 'input--error' : null}
        {...rest}
      />
      {children}
      <ErrorMessage name={name} component="div" className={style['error--text']} />
    </div>
  );
};
