import React, { useEffect, useState } from 'react';
import style from './index.module.scss';

import { Avatar, Drawer, Popover } from 'antd';
import 'antd/dist/antd.css';
import { useAppSelector } from 'src';
import { useDispatch } from 'react-redux';
import { clearUserData, setLoginState } from 'src/redux/Slices/authSlice';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { MdClose, MdSend } from 'react-icons/md';
import { v4 as uuidV4 } from 'uuid';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { usePusherContext } from 'src/context/PusherContext/hooks/usePusherContext';
import { TSingleContentVideos } from 'src/types/contentAndVideoTypes';
import { UserOutlined } from '@ant-design/icons';
// import { GrSearch } from 'react-icons/gr';
import { HiShoppingCart } from 'react-icons/hi';
import { ImBubble } from 'react-icons/im';
import { FaBell } from 'react-icons/fa';
import { Badge } from 'antd';
import { ReactComponent as RecipeIconRegular } from '../../assets/icons/RegularUser/recipe-icon.svg';
import { ReactComponent as ClassesIcon } from '../../assets/icons/RegularUser/classes-icon.svg';
import { ReactComponent as ShopIcon } from '../../assets/icons/RegularUser/shoppingCart-icon.svg';
import { ReactComponent as LiveWebinarIcon } from '../../assets/icons/RegularUser/live-icon.svg';
import { ReactComponent as HomeIcon } from '../../assets/icons/home-icon.svg';
import { ReactComponent as PlayIcon } from '../../assets/icons/LeadChef/play-icon.svg';
import { ReactComponent as AdminIcon } from '../../assets/icons/admin-icon.svg';
import { ReactComponent as CloseIcon } from './assets/icons/close.svg';
import { MdSentimentSatisfied } from 'react-icons/md';
import LocalizedFormat from 'dayjs';
import { AppModal } from 'src/components/Modal';
import { useLocation, useParams } from 'react-router-dom';
import { GrClose } from 'react-icons/gr';
import { DrawerModal } from 'src/components/Modal';
import Messages from '../Messages/Messages';
import useNotificationsNetworkRequest, {
  SingleNotification,
} from './hooks/useNotificationNetworkRequest';
import useComplaintsNetworkRequest from './hooks/useComplaintsNetworkRequest';
import { Button } from 'src/components/Button';
//@ts-expect-error
import CorrectAnswerTone from './assets/audio/mixkit-correct-answer-tone-2870.wav';
import { ImExit } from 'react-icons/im';
dayjs.extend(LocalizedFormat);
dayjs.extend(relativeTime);

export default function TopBarActions({
  ActionType,
  handleAction,
}: {
  ActionType?: 'ecommerce' | 'normal';
  handleAction?: () => void;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [NotificationState, setNotifications] = useState<SingleNotification[]>([]);

  //show notifications
  const [showNotifcations, setShowNotifications] = useState(false);
  const [hasNotifications, setHasNotifications] = useState(false);

  //has new message
  const [hasNewMessage, setHasNewMessage] = useState(false);

  //hook to get all notifications
  const { getAllNotifications, response } = useNotificationsNetworkRequest();
  const { markNnotifications } = useNotificationsNetworkRequest(100);

  // const { data: createConversationResponse} = useChatNetworkRequest(user?._id)
  useEffect(() => {
    getAllNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //check if there's a response and set to Notifications State
    if (response) {
      setNotifications(response.data.results);

      const hasRead = response.data.results
        .filter((item) => item.hasRead)
        .map((item) => item.hasRead);

      if (hasRead.length > 0) {
        setHasNotifications(true);
      } else {
        setHasNotifications(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  const messagesHandler = () => {
    //  createConversation()

    setHasNewMessage(false);

    user?.role !== 'lead_chef'
      ? history.push(`/messages/xVbnC/chat`, {
          background: history.location,
        })
      : setIsOpen(true);
  };

  //handle show notifications
  const handleNotification = () => {
    setShowNotifications(!showNotifcations);
    markNnotifications();
    setHasNotifications(false);
  };

  const { PusherState } = usePusherContext();
  const user = useAppSelector(({ auth }) => auth.userData?.user);

  const audioRef = React.useRef<HTMLAudioElement>(null);

  const createNewNotification = (data: SingleNotification, live?: boolean) => {
    audioRef.current?.play();
    
    const notification: SingleNotification = {
      sender: '',
      actionType: live ? 'webinar' : data.actionType,
      action: data.action,
      hasRead: false,
      _id: data.action ? (data.action as string) : data._id,
      receiver: 'string',
      notificationTitle: live ? 'The Leadchef is live' : data.notificationTitle,
      notificationMessage: live
        ? `Heads up!, The lead chef at ifyskitchen is live at ${data._id}`
        : data.notificationMessage,
      createdAt: data.createdAt,
    };

    setHasNotifications(true);

    setNotifications((prevState) =>
      prevState.length > 0 ? [notification, ...prevState] : [notification]
    );
  };

  useEffect(() => {
    const channel = PusherState?.subscribe(`notification-${user?._id}`);
    const messageChannel = PusherState?.subscribe(user?._id as string);

    channel?.bind('lead-chef-going-live', (data: any) => {
      console.log(data);
      createNewNotification(data, true);
    });

    channel?.bind('new_notification', (data: SingleNotification) => {
      createNewNotification(data);
    });

    //message channel binding
    messageChannel?.bind('lead-chef-chat-message', (data: TSingleContentVideos) => {
      data && setHasNewMessage(true);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let history = useHistory();

  const [isSupportModalOpen] = useState(false);
  const [openMobileMenu, setOpenMobileMenu] = useState(false);

  const openMobileMenuHandler = () => {
    setOpenMobileMenu(!openMobileMenu);
  };
  const handleIsSupportModalOpen = () => {
    // setIsSupportModalOpen(!isSupportModalOpen);
    history.push('/help');
  };

  return (
    <>
      <div className={style['action__area']}>
        {/* <span className={style['action__area--search']}>
          <GrSearch />
        </span> */}
        <audio ref={audioRef} src={CorrectAnswerTone} />
        {ActionType === 'ecommerce' && (
          <span className={style['action__area--MessageBubble']} onClick={handleAction}>
            <HiShoppingCart />
          </span>
        )}
        {ActionType === 'normal' && (
          <>
            <span onClick={messagesHandler} className={style['action__area--MessageBubble']}>
              <Badge count={hasNewMessage ? 1 : 0} size="default" color="#735BF3" dot>
                <ImBubble />
              </Badge>
            </span>
            <span className={style['action__area--bell']} onClick={handleNotification}>
              <Badge count={hasNotifications ? 1 : 0} size="default" color="#735BF3" dot>
                <FaBell />
              </Badge>
            </span>
          </>
        )}
        {user?.role !== 'lead_chef' && (
          <Popover
            content={<ProfileMenu handleOpenSupportModal={handleIsSupportModalOpen} />}
            placement="bottomLeft"
            trigger="click"
            overlayClassName={style['test']}
          >
            <span className={style['action__area--avatar']}>
              <Avatar
                size={30}
                icon={<UserOutlined />}
                src={user?.profileImageUrl as string}
                className={style['avatar']}
              >
                {user?.name.charAt(0) as string}
              </Avatar>
            </span>
          </Popover>
        )}

        {window.innerWidth < 700 ? (
          <span className={style['action__area--avatar']} onClick={openMobileMenuHandler}>
            <Avatar
              size={30}
              icon={<UserOutlined />}
              src={user?.profileImageUrl as string}
              className={style['avatar']}
            >
              {user?.name.charAt(0) as string}
            </Avatar>
          </span>
        ) : ( 
          <>
            {
              user?.role === 'lead_chef' && (
                <Popover
                  content={<ProfileMenu handleOpenSupportModal={handleIsSupportModalOpen} />}
                  placement="bottomLeft"
                  trigger="click"
                  overlayClassName={style['test']}
                >
                  <span className={style['action__area--avatar']}>
                    <Avatar
                      size={30}
                      icon={<UserOutlined />}
                      src={user?.profileImageUrl as string}
                      className={style['avatar']}
                    >
                      {user?.name.charAt(0) as string}
                    </Avatar>
                  </span>
                </Popover>
              )
            }
          </>
        )}

        {
          <Messages
            isOpen={isOpen && !history.location.pathname.includes('messages')}
            handleClose={() => setIsOpen(false)}
          />
        }

        <Notifications
          NotificationState={NotificationState}
          showNotifcations={showNotifcations}
          handleNotification={handleNotification}
        />
      </div>
      <ChatSupportModal />
      <SupportModal isOpen={isSupportModalOpen} handleSupportModalOpen={handleIsSupportModalOpen} />
      <LeadChefMobileMenu isOpen={openMobileMenu} openMobileMenuHandler={openMobileMenuHandler} />
    </>
  );
}

export const LeadChefMobileMenu = ({
  isOpen,
  openMobileMenuHandler,
}: {
  isOpen: boolean;
  openMobileMenuHandler: () => void;
}) => {
  const sidebarList = [
    {
      title: 'Dashboard',
      icon: <HomeIcon className={style['sidebar--icon']} />,
      route: '/lead-chef/dashboard',
    },
    {
      title: 'Videos',
      icon: <PlayIcon className={style['sidebar--icon']} />,
      route: '/lead-chef/videos',
    },
    {
      title: 'Recipes',
      icon: <RecipeIconRegular className={style['sidebar--icon']} />,
      route: '/lead-chef/recipes',
    },
    {
      title: 'Classroom',
      icon: <ClassesIcon className={style['sidebar--icon']} />,
      route: '/lead-chef/classrooms',
    },
    {
      title: 'Live Webinars',
      icon: <LiveWebinarIcon className={style['sidebar--icon']} />,
      route: '/lead-chef/webinar',
    },
    {
      title: 'Shop',
      icon: <ShopIcon className={style['sidebar--icon']} />,
      route: '/lead-chef/shop',
    },
  ];
  const dispatch = useDispatch();
  const userDetails = useAppSelector((state) => state.auth.userData?.user);

  const logout = () => {
    dispatch(clearUserData());
    dispatch(setLoginState(false));
  };

  return (
    <Drawer
      placement="left"
      closable={false}
      onClose={openMobileMenuHandler}
      visible={isOpen}
      key="left"
      width="300px"
    >
      <div className={style['mobile_leadchef_container']}>
        <div className={style['mobile_leadchef_close']} onClick={openMobileMenuHandler}>
          <CloseIcon />
        </div>
        <header className={style['mobile_leadchef_header']}>
          <Avatar
            size={40}
            icon={<UserOutlined />}
            src={userDetails?.profileImageUrl as string}
            className={style['avatar']}

          >
            {userDetails?.name.charAt(0) as string}
          </Avatar>
          <div className={style['profile__menu--details']}>
            <span className={style['user__type']}>{userDetails?.role}</span>
            <h4>{userDetails?.name}</h4>
            <span className={style['user__email']}>{userDetails?.email}</span>
          </div>
        </header>
        <ul className={style['list--container--leadchef']}>
          {sidebarList.map((listItem, index) => {
            return (
              <NavLink
                to={listItem.route}
                className={style['link--wrapper']}
                activeClassName={style['active']}
                key={index}
              >
                <li className={style['list--item']}>
                  <span className={style['icon']}>{listItem.icon}</span>
                  <span className={style['text']}> {listItem.title}</span>
                </li>
              </NavLink>
            );
          })}
          <NavLink
            className={style['link--wrapper']}
            activeClassName={style['active']}
            exact
            to="/lead-chef/administrators"
          >
            <li className={style['list--item']}>
              <span className={style['icon']}>
                <AdminIcon className={style['sidebar--icon']} />
              </span>
              <span className={style['text']}>Admin Room</span>
            </li>
          </NavLink>
        </ul>

        <ul className={style['listItem--isolated']}>
          <li className={style['list--item']}>
            <span className={style['icon']}>
              <ImExit className={style['sidebar--icon']} />
            </span>
            <span className={style['text']} onClick={logout}>
              Logout
            </span>
          </li>
        </ul>
      </div>
    </Drawer>
  );
};

const ProfileMenu = ({ handleOpenSupportModal }: { handleOpenSupportModal: () => void }) => {
  const dispatch = useDispatch();
  const userDetails = useAppSelector((state) => state.auth.userData?.user);

  const logout = () => {
    localStorage.removeItem('token');
    dispatch(clearUserData());
    dispatch(setLoginState(false));
  };

  const handleRole = (role: string | undefined) => {
    switch (role) {
      case 'lead_chef':
        return 'Lead Chef';
      case 'admin':
        return 'admin';
      case 'regular':
        return 'admin';

      default:
        break;
    }
  };
  return (
    <div className={style['profile__menu']}>
      <header>
        <Avatar
          size={40}
          icon={<UserOutlined />}
          src={userDetails?.profileImageUrl as string}
          className={style['avatar']}
        >
          {userDetails?.name.charAt(0) as string}
        </Avatar>
        <div className={style['profile__menu--details']}>
          <span className={style['user__type']}>{handleRole(userDetails?.role)}</span>
          <h4>{userDetails?.name}</h4>
          <span className={style['user__email']}>{userDetails?.email}</span>
        </div>
      </header>
      <hr />

      <ul>
        {userDetails?.role?.toLowerCase() === 'user' && (
          <>
            <NavLink to="/profile">
              <li className={style['list__item']}>My Account</li>
            </NavLink>
            <NavLink to="/collections">
              <li className={style['list__item']}>Collections</li>
            </NavLink>
            <li className={style['list__item']} onClick={handleOpenSupportModal}>
              Help and Support
            </li>
          </>
        )}
      </ul>
      <hr />
      <span className={style['list__item']} onClick={() => logout()}>
        Logout
      </span>
    </div>
  );
};

const Notifications = ({
  NotificationState,
  showNotifcations,
  handleNotification,
}: {
  NotificationState: SingleNotification[];
  showNotifcations: boolean;
  handleNotification: () => void;
}) => {
  const history = useHistory();
  return (
    <div
      className={`${
        showNotifcations ? style['notification--container'] : style['notification--container-close']
      }`}
    >
      <header className={style['notification--header']}>
        <div className={style['notification--title']}>
          <h1>Notifications</h1>
        </div>
        <MdClose onClick={handleNotification} className={style['close-notification']} />
      </header>
      <hr />
      <ul className={style['notification--item-container']}>
        {NotificationState.map((notification) => {
          return (
            <li key={uuidV4()} className={style['notification--item']}>
              <NotificationItem
                notification={notification}
                actionType={notification.actionType}
                location={history.location}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const NotificationItem = ({
  actionType,
  notification,
  location,
}: {
  actionType: string;
  notification: SingleNotification;
  //@ts-expect-error
  location: History<unknown>;
}) => {
  switch (actionType.toLowerCase()) {
    case 'message':
      return (
        <Link
          to={{
            pathname: `/messages/${notification.action}/chat`,
            state: {
              background: location,
            },
          }}
        >
          {/* <img src="https://i.pravatar.cc/50" alt="" /> */}

          <div className={style['notification--item-section']}>
            <p className={style['notification--item-title']}>{notification.notificationTitle}</p>
            <p className={style['notification--item-text']}>{notification.notificationMessage}</p>
            <p className={style['notification--item-date']}>
              {dayjs(notification.createdAt).fromNow()}
            </p>
          </div>
        </Link>
      );
    case 'payment':
      return (
        <>
          {/* <img src="https://i.pravatar.cc/50" alt="" /> */}

          <div className={style['notification--item-section']}>
            <p className={style['notification--item-title']}>{notification.notificationTitle}</p>
            <p className={style['notification--item-text']}>{notification.notificationMessage}</p>
            <p className={style['notification--item-date']}>
              {dayjs(notification.createdAt).fromNow()}
            </p>
          </div>
        </>
      );
    case 'classroom':
      return (
        <Link
          to={{
            pathname: `/classroom/${notification.action}`,
            state: {
              background: location,
            },
          }}
        >
          {/* <img src="https://i.pravatar.cc/50" alt="" /> */}

          <div className={style['notification--item-section']}>
            <p className={style['notification--item-title']}>{notification.notificationTitle}</p>
            <p className={style['notification--item-text']}>{notification.notificationMessage}</p>
            <p className={style['notification--item-date']}>
              {dayjs(notification.createdAt).fromNow()}
            </p>
          </div>
        </Link>
      );
    case 'content':
      return (
        <>
          {/* <img src="https://i.pravatar.cc/50" alt="" /> */}

          <div className={style['notification--item-section']}>
            <p className={style['notification--item-title']}>{notification.notificationTitle}</p>
            <p className={style['notification--item-text']}>{notification.notificationMessage}</p>
            <p className={style['notification--item-date']}>
              {dayjs(notification.createdAt).fromNow()}
            </p>
          </div>
        </>
      );
    case 'others':
      return (
        <>
          {/* <img src="https://i.pravatar.cc/50" alt="" /> */}

          <div className={style['notification--item-section']}>
            <p className={style['notification--item-title']}>{notification.notificationTitle}</p>
            <p className={style['notification--item-text']}>{notification.notificationMessage}</p>
            <p className={style['notification--item-date']}>
              {dayjs(notification.createdAt).fromNow()}
            </p>
          </div>
        </>
      );

    case 'webinar':
      return (
        <>
          <Link
            to={{
              pathname: `/live-webinar/${notification.action}`,
              state: {
                background: location,
              },
            }}
          >
            {/* <img src="https://i.pravatar.cc/50" alt="" /> */}

            <div className={style['notification--item-section']}>
              <p className={style['notification--item-title']}>{notification.notificationTitle}</p>
              <p className={style['notification--item-text']}>
                {notification.notificationMessage.substring(0, 84) + '...'}
              </p>
              <p className={style['notification--item-date']}>
                {dayjs(notification.createdAt).fromNow()}
              </p>
            </div>
          </Link>
        </>
      );
    case 'order':
      return (
        <>
          {/* <img src="https://i.pravatar.cc/50" alt="" /> */}

          <div className={style['notification--item-section']}>
            <p className={style['notification--item-title']}>{notification.notificationTitle}</p>
            <p className={style['notification--item-text']}>{notification.notificationMessage}</p>
            <p className={style['notification--item-date']}>
              {dayjs(notification.createdAt).fromNow()}
            </p>
          </div>
        </>
      );

    default:
      throw new Error('Notification type not found');
  }
};

export const ChatSupportModal = () => {
  const [inputValue, setInputValue] = useState('');

  const [allMessages, setAllMessages] = useState<
    { type: 'receiver' | 'sender'; message: string; createdAt: Date | string }[]
  >([]);

  const { conversationId } = useParams<{ conversationId: string }>();

  const { sendReply, FetchReplies, activityResponse } = useComplaintsNetworkRequest(conversationId);

  const handleInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const { PusherState } = usePusherContext();
  const { goBack } = useHistory();

  const user = useAppSelector((state) => state.auth.userData?.user);

  const handleSubmit = () => {
    sendReply({
      message: inputValue,
    });

    setAllMessages([
      ...allMessages,
      {
        type: 'sender',
        message: inputValue,
        createdAt: new Date() as Date,
      },
    ]);

    setInputValue('');
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  useEffect(() => {
    FetchReplies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationId]);

  const userData = useAppSelector((state) => state.auth.userData);

  useEffect(() => {
    if (activityResponse?.data?.results.length) {
      setAllMessages(
        activityResponse.data.results.map((inComingMessage) => ({
          message: inComingMessage.reply,
          type: inComingMessage.actionBy === userData?.user?._id ? 'sender' : 'receiver',
          createdAt: inComingMessage.createdAt,
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityResponse?.data?.results]);

  type TComplaintReply = {
    _id: string;
    eventType: string;
    complaintId: string;
    userId: string;
    reply: string;
    actionBy: string;
    createdAt: string;
    updatedAt: string;
  };
  useEffect(() => {
    const channel = PusherState?.subscribe(`${conversationId}`);

    channel?.bind('complaint-reply-event', (data: TComplaintReply) => {
      if (data.actionBy !== user?._id) {
        const replyObject = {
          message: data.reply,
          type: 'receiver' as 'receiver',
          createdAt: data.createdAt,
        };

        setAllMessages((prevMessages) =>
          prevMessages.length > 0 ? [...prevMessages, replyObject] : [replyObject]
        );
      }
    });
  }, [PusherState, conversationId, user?._id]);

  return (
    <DrawerModal isOpen={!!conversationId} onClickOutside={() => goBack()}>
      <div className={style['drawer-modal--container']}>
        <header className={style['drawer-modal--header']}>
          <div>
            <img src="" alt="" />

            <h1>Chat with us</h1>
          </div>

          <GrClose className={style['close-icon']} onClick={() => goBack()} />
        </header>

        <ul className={style['drawer-modal--chats']}>
          {allMessages.map((item, index) => {
            return (
              <li
                key={index}
                className={`${style['drawer-modal--chat']} ${
                  item.type === 'sender' ? style['sender'] : style['reciever']
                }`}
              >
                <p className={style['message']}>{item.message}</p>
                <p>{dayjs(new Date()).format('LLL')}</p>
              </li>
            );
          })}
        </ul>

        <div className={style['drawer-modal--inputbox']}>
          <button onClick={handleSubmit} type="button" className={style['chat__actions--btn']}>
            <MdSentimentSatisfied className={style['action']} />
          </button>
          <input
            onChange={handleInputValue}
            value={inputValue}
            onKeyDown={handleKeyDown}
            placeholder="start typing.."
          />
          <button onClick={handleSubmit} type="button" className={style['chat__actions--btn']}>
            <MdSend className={`${style['action__icons--send']} ${style['action']}`} />
          </button>
        </div>
      </div>
    </DrawerModal>
  );
};

const SupportModal = ({
  isOpen,
  handleSupportModalOpen,
}: {
  isOpen: boolean;
  handleSupportModalOpen: () => void;
}) => {
  const { createComplaint, createComplaintResponse, isCreatingComplaint } =
    useComplaintsNetworkRequest();
  const [complaint, setComplaint] = useState<{
    [x: string]: string;
  }>({
    title: '',
    description: '',
  });
  const { push } = useHistory();
  const location = useLocation();

  const handleSubmit = () => {
    createComplaint({
      title: complaint.title,
      description: complaint.description,
    });
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const value = e.target.value;
    const name = e.target.name;
    setComplaint({ ...complaint, [name]: value });
  };

  useEffect(() => {
    if (createComplaintResponse) {
      push(`/help/${createComplaintResponse.data._id}/chat`, {
        settings: location,
      });
      handleSupportModalOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createComplaintResponse]);

  return (
    <AppModal isOpen={isOpen} onClickOutside={handleSupportModalOpen}>
      <div className={style['supportModal--body']}>
        <header>
          <GrClose onClick={handleSupportModalOpen} className={style['close-icon']} />
        </header>

        <div className={style['chat--info']}>
          <img src="" alt="" />

          <h1 className={style['chat--info--title']}>Chat with Us</h1>
          <p className={style['chat--info--subtitle']}>How can we help you today, Alison?</p>
        </div>

        <input type="text" placeholder="Title" name="title" onChange={handleChange} />

        <textarea
          name="description"
          onChange={handleChange}
          id=""
          cols={30}
          placeholder="A brief description of the help or inquiry"
          rows={10}
        />
        <Button
          type="submit"
          variant="secondary"
          onClick={handleSubmit}
          isLoading={isCreatingComplaint}
        >
          Start Conversation
        </Button>
      </div>
    </AppModal>
  );
};
