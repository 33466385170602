import React from 'react';
import { NotificationContext } from '..';

export function useNotificationContext() {
  const context = React.useContext(NotificationContext);

  if (context === undefined) {
    throw new Error('useNotificationContext must be used within a NotificationContext');
  }

  return context;
}
