import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'src';
import { comments } from 'src/hooks/types';
import useRecorder from '../../hooks/useRecorder';
import { v4 as uuidv4 } from 'uuid';
import style from './index.module.scss';
import { FaStop, FaTrash } from 'react-icons/fa';
import { MdAttachFile, MdMic, MdSend, MdSentimentSatisfied } from 'react-icons/md';
import { AudioVisualization } from '../AudioVisualization';
import { Popover } from 'antd';
import { EmojiSelector } from 'src/components/EmojiSelector';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs';
import { IoCloseCircleSharp } from 'react-icons/io5';
import { TClassroomUpdate } from '../../subroute/types';
dayjs.extend(LocalizedFormat);

/**
 * This contains the input and corresponding actions such as record, attach files etc.
 * @param addMessages - (params: comments) => void
 * @param disabled - boolean
 * @returns JSX Element
 */
const InputBox = ({
  addMessages,
  sendMessage,
  isDisabled,
}: {
  addMessages: (params: comments) => void;
  sendMessage: (body?: unknown, params?: unknown) => void;
  isDisabled: TClassroomUpdate['commentsDisabled'];
}) => {
  const [filePreview, setFilePreview] = useState<{ id: string; result: string }[]>([]);
  const [showEmojiSelection, setshowEmojiSelection] = useState(false);
  const [fileData, setFileData] = useState<File | null>(null);

  const [inptValues, setInputValues] = useState<string>('');
  const [showUsers, setshowUsers] = useState(false);
  const [filterValues, setFilterValues] = useState<string | null>(null);
  const [users] = useState<string[]>(['micheal', 'kenny', 'joseph']);

  const { audioBlob, recordString, isRecording, handleRecord, resetRecordState } = useRecorder();

  const user = useAppSelector((state) => state.auth.userData?.user);

  const SubmitValue = () => {
    if (isDisabled && user?.role !== 'lead_chef') {
      return;
    }
    isRecording && handleRecord();
    const images = filePreview.map((item) => item.result);
    const audioRecording = audioBlob;

    const formData = new FormData();
    inptValues.trim() && formData.append('message', inptValues);
    fileData && formData.append('imageAttachments', fileData as File);
    audioRecording &&
      formData.append('audioAttachments', audioRecording as File, `audioRecord-${user?._id}.ogg`);

    if (!inptValues.trim() && !audioRecording && !fileData) return;
    sendMessage(formData);

    addMessages({
      imageUrls: images.length > 0 ? images : [],
      audioUrls: audioRecording ? [recordString as string] : [],
      message: inptValues,
      _userId: {
        name: user?.name as string,
        _id: user?._id,
        profileImageUrl: user?.profileImageUrl as string
      },
    });

    setFilePreview([]);
    setInputValues('');
    setFileData(null);
    resetRecordState();
  };

  const addAttachement = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files?.length > 0) {
      setFileData(e.target.files[0]);
      Array.from(e.target.files).forEach((element) => {
        const reader = new FileReader();

        reader.onload = (function (_theFile) {
          return function (e: ProgressEvent<FileReader>) {
            setFilePreview([{ id: uuidv4(), result: e.target?.result as string }]);
            // Render thumbnail.
          };
        })(element);

        reader.readAsDataURL(element);
      });
    }
  };

  const removeAttachment = (idx: string) => {
    const filteredImg = filePreview.filter((item) => item.id !== idx);
    setFilePreview(filteredImg);
  };

  // handle emoji
  const setEmoji = (emoji: string) => {
    const newValues = inptValues;

    setInputValues(`${newValues} ${emoji}`);
    setshowEmojiSelection(false);
  };

  //To optimize mention system
  // const [terminalLengths, setTerminalLengths] = useState<string[]>([]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value as string;
    setInputValues(value);
  };
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      SubmitValue();
    }
  };

  useEffect(() => {
    const res = inptValues.split('@');
    if (res.length > 1) {
      setFilterValues(res[res.length - 1]);
    }
  }, [inptValues]);

  useEffect(() => {
    if (filterValues !== null && inptValues.trim().length > 0) {
      setshowUsers(true);
    } else {
      setshowUsers(false);
    }
  }, [filterValues, inptValues]);

  return (
    <>
      <div className={style['chatarea--inputbox-container']}>
        {(filePreview.length > 0 || recordString) && (
          <div className={style['selected-file-previews']}>
            {recordString && (
              <div className={style['waveform--record-preview']}>
                <div className={style['waveform--wrapper']}>
                  <AudioVisualization url={recordString} container={`waveform-${uuidv4()}`} />
                  <p className={style['record-date']}>{dayjs(new Date()).format('LLL')}</p>
                </div>

                <IoCloseCircleSharp className={style['remove--icon']} onClick={resetRecordState} />
              </div>
            )}

            {filePreview.length > 0 && (
              <div className={style['file_preview']}>
                {filePreview.map((item) => (
                  <div key={item.id} className={style['image__wrapper']}>
                    <img src={item.result} alt="file preview" />
                    <div className={style['overlay']}>
                      <FaTrash onClick={() => removeAttachment(item.id)} />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
        {showUsers && (
          <ul className={style['mentions-list']}>
            {users
              .filter((item) => item.indexOf(filterValues?.replace('@', '') || '') > -1)
              .map((item) => (
                <li
                  className={style['mentions-list--item']}
                  onClick={() => {
                    setshowUsers(false);
                    setFilterValues(null);
                    setInputValues(`${inptValues} ${item}`);
                  }}
                >
                  {item}
                </li>
              ))}
          </ul>
        )}

        <div className={style['chat__input']}>
          {(!recordString || isRecording) && !inptValues && (
            <label htmlFor="attachment" className={style['attachment__wrapper']}>
              <MdAttachFile className={style['action__icons--attach']} />
              <input
                type="file"
                accept=".jpg, .png, .jpeg"
                name=""
                id="attachment"
                onChange={addAttachement}
                disabled={(isRecording || isDisabled) && user?.role !== 'lead_chef'}
              />
            </label>
          )}

          <div className={style['editor']}>
            <input
              type="text"
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              value={inptValues}
              placeholder={!isRecording ? 'Type something...' : 'Recording'}
              disabled={isRecording || isDisabled}
            />
          </div>

          {!inptValues && (
            <button type="button" className={style['chat__actions--btn']} onClick={handleRecord}>
              {isRecording ? (
                <FaStop className={style['action__icons--stop']} />
              ) : (
                <MdMic className={style['action__icons--mic']} />
              )}
            </button>
          )}
          {(!recordString || isRecording) && (
            <Popover
              content={<EmojiSelector setEmoji={setEmoji} className={style['emoji--wrapper']} />}
              trigger="click"
              visible={showEmojiSelection}
              placement="leftTop"
              onVisibleChange={() => setshowEmojiSelection(true)}
            >
              <button type="button" className={style['chat__actions--btn']}>
                <MdSentimentSatisfied className={style['action__icons--emoji']} />
              </button>
            </Popover>
          )}
          <button onClick={SubmitValue} type="button" className={style['chat__actions--btn']}>
            <MdSend className={style['action__icons--send']} />
          </button>
        </div>
      </div>
    </>
  );
};

export default InputBox;
