export const usePlanPricePoint = () => {
  const handlePricePointName = (pricePointName: string) => {
    switch (pricePointName.toLowerCase()) {
      case "year":
        return "Yearly";
      case "month":
        return "Monthly";
      case "half_year":
        return "Half Year";
      default:
        return null;
    }
  };

  return { handlePricePointName };
};
