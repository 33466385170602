import React, { useEffect, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';
import style from './audioVisualization.module.scss';
import { FaPlayCircle, FaPauseCircle } from 'react-icons/fa';
import { useAppSelector } from 'src';

const AudioVisualization = ({
  url,
  container,
  progressColor,
}: {
  url: string;
  container: string;
  progressColor?: string;
}) => {
  const [waveform, setWaveform] = useState<WaveSurfer | null>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [duration, setduration] = useState<number>(0);
  const token = useAppSelector((state) => state.auth.accessToken);

  useEffect(() => {
    let xhr = {
      cache: 'default',
      mode: 'cors',
      method: 'GET',
      credentials: 'same-origin',
      redirect: 'follow',
      referrer: 'client',
      headers: [{ key: 'Authorization', value: `bearer ${token}` }],
    };
    const wavesurfer = WaveSurfer.create({
      container: `#${container}`,
      // backend: specify webAudio, mediaelement etc
      scrollParent: false,
      waveColor: '#ffffffcc',
      progressColor: `${progressColor ? progressColor : '#5D41F1'}`,
      barHeight: 5,
      barMinHeight: 2.5,
      barRadius: 5,
      barWidth: 4,
      cursorWidth: 0,
      height: 87, // in px
      hideScrollbar: true,
      responsive: true,
      xhr,
    });

    wavesurfer?.load(url);
    setWaveform(wavesurfer);

    return () => {
      wavesurfer?.destroy();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  const playAudio = () => {
    setIsPlaying(true);
    waveform?.play();
  };

  const pauseAudio = () => {
    setIsPlaying(false);
    waveform?.pause();
  };

  const calculateDuration = (durationInSeconds: number) => {
    const minutes = Math.floor(durationInSeconds / 60);
    const seconds = Math.floor(durationInSeconds - minutes * 60);
    return `${minutes}:${seconds < 10 ? 0 : ''}${seconds}`;
  };

  useEffect(() => {
    if (waveform) {
      getCurrentTime();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waveform]);

  const getCurrentTime = () => {
    waveform?.on('audioprocess', function () {
      setduration(waveform?.getCurrentTime());
    });
  };

  return (
    <div className={style['waveform-container']}>
      {isPlaying ? (
        <FaPauseCircle className={style['audio-action-icon']} onClick={pauseAudio} />
      ) : (
        <FaPlayCircle className={style['audio-action-icon']} onClick={playAudio} />
      )}

      <div className={style['waveform']}>
        <div id={`${container}`}></div>
      </div>

      <p className={style['waveform-duration']}>{calculateDuration(duration)}</p>
    </div>
  );
};

export default React.memo(AudioVisualization);
