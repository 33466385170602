import React from "react";
import FeatureCard from "../FeatureCard/FeatureCard";
import ShieldDone from "../../Iconly/ShieldDone.svg";
import TimeCircle from "../../Iconly/TimeCircle.svg";
import Graph from "../../Iconly/Graph.svg";
import Activity from "../../Iconly/Activity.svg";
import "./Features.css";

function Features() {
  const features = [
    {
      source: ShieldDone,
      header: "Quality Assured",
      content:
        "We offer you premium content that brings all the good food recipes and put them in one place",
    },
    {
      source: TimeCircle,
      header: "Time Saving",
      content:
        "We believe in effective time management and this is evident in our cooking methods and process",
    },
    {
      source: Graph,
      header: "Cost Effective",
      content:
        "Amazing, nutritious meals shouldn’t put a hole in your pocket. With us, ingredients within your reach are priority",
    },
    {
      source: Activity,
      header: "Boost your income",
      content:
        "With our product offerings, we create an avenue to scale your food business and double your income!",
    },
  ];

  return (
    <section className="features-section">
      <div className="features-wrapper">
        <div className="features-title">
          Why is Ify’s Kitchen <br /> your best bet?
        </div>
        <div className="features-container">
          {features.map((feature, index) => {
            return (
              <FeatureCard
                key={index}
                source={feature.source}
                header={feature.header}
                content={feature.content}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default Features;
