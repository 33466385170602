import styles from "./button.module.scss";
import { ButtonProps } from "./utils/interface";
import Loader from "react-loader-spinner";

const Button = ({
  style,
  variant = "primary",
  children,
  className,
  ...rest
}: ButtonProps): JSX.Element => {
  return (
    <button
      style={style}
      className={
        (className ? `${className} ` : "") + `${styles[`--${variant}`]}`
      }
      {...rest}
    >
      {rest.isLoading && (
        <span className={styles["loading-icon"]}>
          {
            // @ts-ignore
            <Loader
              type="TailSpin"
              color="#fff"
              height={35}
              width={35}
              visible={rest.isLoading}
            />
          }
        </span>
      )}
      {children}
    </button>
  );
};

export default Button;
