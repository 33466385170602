import { useEffect, useRef, useState } from 'react';
import { RiErrorWarningFill } from 'react-icons/ri';
import { comments } from 'src/hooks/types';
import { classActions, TChatAreaProps } from '../../subroute/types';
import { AudioVisualization } from '../AudioVisualization';
import ClassEventNotice from '../ClassEventNotice';
import { v4 as uuidv4 } from 'uuid';
import { useAppSelector } from 'src';
import { useHistory, useParams } from 'react-router';
import { usePusherContext } from 'src/context/PusherContext/hooks/usePusherContext';
import { Popover } from 'antd';
import style from './index.module.scss';
import useSendComment from 'src/hooks/Classroom/useSendComment';
import MenuIcon from '../../assets/icons/more-icon.svg';
import InputBox from '../InputBox';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs';
import { AxiosError } from 'axios';
import SmallImage from './components/image';
// import EnlargedImage from './components/enlargedImage';
dayjs.extend(LocalizedFormat);

const ChatArea = ({
  singleClass,
  setIsComponentVisible,
  handleSidebar,
  handleClassActions,
  isDisabled,
  isActive,
}: TChatAreaProps) => {
  const [messages, setMessages] = useState<comments[]>(singleClass?.comments as comments[]);
  const user = useAppSelector((state) => state.auth.userData?.user);
  const history = useHistory();
  const { route } = useParams<{ route: string }>();
  const [inputError, setInputError] = useState<AxiosError | null>(null);
  const { PusherState } = usePusherContext();
  const scrollRef = useRef<HTMLLIElement>(null);

  const { sendMessage, error: sendMessageError } = useSendComment(route);

  const handleMessage = (text: comments) => {
    setMessages([...messages, text]);
  };

  const scrollToBottom = () => {
    scrollRef.current?.scrollIntoView();
  };


  useEffect(() => {
    const channel = PusherState?.subscribe(`classroomComment-${route}`);
    channel?.bind('new_classroom_comment', (data: comments) => {
      if (data._userId?._id !== user?._id) {
        setMessages((prevMessages) => (prevMessages.length > 0 ? [...prevMessages, data] : [data]));
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    scrollToBottom();
  }, []);

  useEffect(() => {
    setInputError(sendMessageError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  // console.log(messages)

  return (
    <main className={style['chatarea']}>
      <header className={style['chatarea--header']}>
        <img
          src={singleClass?.thumbnail ?? `https://ui-avatars.com/api/?name=${singleClass?.name}`}
          alt=""
          onClick={() => setIsComponentVisible(true)}
          className={style['classroom--thumbnail']}
        />
        <div className={style['header--wrapper']}>
          <h2>{singleClass?.name}</h2>
          {(isActive || user?.role === 'lead_chef') && (
            <div className={style['action__area--wrapper']}>
              <span className={style['header--participants']} onClick={handleSidebar}>
                {singleClass?.attendees.length} participant(s)
              </span>
              <Popover
                trigger="click"
                placement="topLeft"
                content={
                  <div className={style['menu-popover--container']}>
                    {user?.role === 'lead_chef' && !isDisabled && isActive && (
                      <div
                        className={style['menu--option']}
                        onClick={() => handleClassActions(classActions.DISABLE_COMMENTS)}
                      >
                        <h4>Disable Comments</h4>
                        <p>Do not allow participants to send messages to the classroom.</p>
                      </div>
                    )}

                    {user?.role === 'lead_chef' && isDisabled && isActive && (
                      <div
                        className={style['menu--option']}
                        onClick={() => handleClassActions(classActions.ENABLE_COMMENTS)}
                      >
                        <h4>Enable Comments</h4>
                        <p>Allow participants to send messages to the classroom.</p>
                      </div>
                    )}

                    {user?.role === 'lead_chef' && !isActive && (
                      <div
                        className={style['menu--option']}
                        onClick={() => handleClassActions(classActions.ACTIVATE_CLASSROOM)}
                      >
                        <h4>Activate Classroom</h4>
                        <p>Allow participants to send messages to the classroom.</p>
                      </div>
                    )}

                    {user?.role === 'lead_chef' && isActive ? (
                      <div
                        className={`${style['menu--option']} ${style['leave-classroom']}`}
                        onClick={() => handleClassActions(classActions.END_CLASS)}
                      >
                        <h4>End Classroom</h4>
                        <p>This class will end and class would be set to read only</p>
                      </div>
                    ) : (
                      <div
                        className={`${style['menu--option']} ${style['leave-classroom']}`}
                        onClick={() => history.push('/classes')}
                      >
                        <h4>Exit Classroom</h4>
                      </div>
                    )}
                  </div>
                }
              >
                <span className={style['action__area--menu']}>
                  <img src={MenuIcon} alt="" />
                </span>
              </Popover>
            </div>
          )}
        </div>
      </header>

      {isActive && isDisabled && (
        <div className={style['class-event-notice']}>
          <ClassEventNotice
            isDisabled={isDisabled}
            isActive={isActive}
            handleClick={() => handleClassActions(classActions.ENABLE_COMMENTS)}
          />
        </div>
      )}
      <ul ref={scrollToBottom} className={style['chatarea--message-box']}>
        {messages &&
          messages.length > 0 &&
          messages.map((messageItem) => (
            <li
              key={uuidv4()}
              className={`${style['chat__message--item']} ${
                messageItem._userId?._id === user?._id ? style['sender'] : style['reciever']
              }`}
            >
              <img
                src={
                  messageItem._userId?.profileImageUrl 
                }
                alt="profile"
                className={style['chat--sender-img']}
                style={{
                  marginBottom: '1rem',
                }}
              />
              <div className={style['chat--content-wrapper']}>
                <div className={style['chat--content-user']}>{messageItem._userId?.name}</div>
                 <SmallImage messageItem={messageItem}/>
                
                {messageItem.audioUrls && messageItem.audioUrls?.length > 0 && (
                  <div className={style['waveform--wrapper']}>
                    <AudioVisualization
                      url={messageItem.audioUrls[0]}
                      container={`waveform-${uuidv4()}`}
                      progressColor={messageItem._userId?._id === user?._id ? '#CC9CF4' : ''}
                    />

                    {/* <p>{dayjs(new Date()).format('LT')}</p> */}
                  </div>
                )}

                {messageItem.message?.trim() && (
                  <div className={`${style['chat--content']} ${style['chat--message']}`}>
                    <span className={style['message']}>{messageItem.message}</span>
                    <span className={style['chat__time']}>
                      {dayjs(messageItem.createdAt).format('LLL')}
                    </span>
                  </div>
                )}
              </div>
              {inputError && (
                <RiErrorWarningFill style={{ alignSelf: 'center', margin: '.5rem' }} />
              )}
            </li>
          ))}
        <li ref={scrollRef}></li>
      </ul>

      {isActive ? (
        <InputBox isDisabled={isDisabled} addMessages={handleMessage} sendMessage={sendMessage} />
      ) : (
        <ClassEventNotice
          isDisabled={isDisabled}
          isActive={isActive}
          handleClick={() => handleClassActions(classActions.ENABLE_COMMENTS)}
        />
      )}
    </main>
  );
};

export default ChatArea;
