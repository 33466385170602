import { ReactNode } from 'react';
import style from './authLayout.module.scss';

import useAdminType from './hooks/useAdminType';

export default function AuthLayout({
  children,
  userType,
}: {
  children: ReactNode;
  userType: string;
}) {
  const { handleAdminType } = useAdminType();
  return (
    <div className={style['_']}>
      <section className={style['section--one']}>{handleAdminType(userType)}</section>
      <section className={style['section--two']}>{children}</section>
    </div>
  );
}
