import { useMemo } from 'react';
import EmojiCharacters from 'src/assets/Emoji/emoji.json';
import style from './EmojiSelector.module.scss';

export default function EmojiSelector({
  setEmoji,
  className,
}: {
  setEmoji: (args: string) => void;
  className?: string;
}) {
  const Emoji = useMemo(() => EmojiCharacters, []);

  return (
    <div className={`${style['emoji--grid']} ${className}`}>
      {Emoji.map((item) => (
        <span className={style['emoji--span']} onClick={() => setEmoji(item.emoji)}>
          {item.emoji}{' '}
        </span>
      ))}
    </div>
  );
}
