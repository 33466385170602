import React from 'react';
import './Footer.scss';
import instagram from '../Footer/assets/images/instagram.svg';
import facebook from '../Footer/assets/images/facebook.svg';
import twitter from '../Footer/assets/images/twitter.svg';
import youtube from '../Footer/assets/images/youtube.svg';
import email from '../Footer/assets/images/email.svg';
// import tel from '../Footer/assets/images/tel.svg';
import { useHistory, useLocation } from 'react-router-dom';

function Footer() {
  const d = new Date();
  const year = d.getFullYear();
  let history = useHistory();
  let location = useLocation();

  function scrollToTop() {
    history.push('/AboutUs');
    let element = document.getElementById('nav');
    let elementOffset = element.getBoundingClientRect().top;
    window.scrollBy(0, elementOffset);
  }

  function scrollToSubscriptions() {
    if (location.pathname === '/') {
      let element = document.getElementById('subscription-section');
      let elementOffset = element.getBoundingClientRect().top;
      window.scrollBy(0, elementOffset);
    } else {
      history.push('/');
      setTimeout(function () {
        let element = document.getElementById('subscription-section');
        let elementOffset = element.getBoundingClientRect().top;
        window.scrollBy(0, elementOffset);
      }, 800);
    }
  }

  function scrollToFAQ() {
    if (location.pathname === '/') {
      let element = document.getElementById('FAQ-section');
      let elementOffset = element.getBoundingClientRect().top;
      window.scrollBy(0, elementOffset);
    } else {
      history.push('/');
      setTimeout(function () {
        let element = document.getElementById('FAQ-section');
        let elementOffset = element.getBoundingClientRect().top;
        window.scrollBy(0, elementOffset);
      }, 800);
    }
  }
  return (
    <footer>
      <div className="footer-container">
        <div className="company-reach-group">
          <div className="company-container">
            <h4 className="footer-sub-header">Company</h4>
            <ul>
              <li>
                <p onClick={scrollToTop}>About Us</p>
              </li>
              <li>
                <p onClick={scrollToSubscriptions}>Subscriptions</p>
              </li>
              <li>
                <p onClick={scrollToFAQ}>FAQ’s</p>
              </li>
              {/* <li>
                o="/Contact">Contact Us<              </li>
              <li>
                o="/Testimonials">Testimonials<              </li> */}
            </ul>
          </div>
          <div className="reach-us-container">
            <h4 className="footer-sub-header">Reach us</h4>
            <div className="reach-foot-container">
              <p>
                <img src={email} alt="" />
              </p>{' '}
              <a className="email" href="mailto:info@ifyskitchen.com">
                info@ifyskitchen.com
              </a>
            </div>
            {/* <div className="reach-foot-container">
              <p>
                <img src={tel} alt="" />
              </p>{' '}
              <a className="email" href="tel:+234 2525 2525">
                +234 2525 2525
              </a>
            </div> */}
          </div>
        </div>

        <>
          <div className="copyright-container">
            <div className="copyright-wrapper">
              <div className="copyright">Copyright &copy; {year} Ify's Kitchen</div>
              <div className="copyright">All rights reserved</div>
              <div className="images-container">
                {' '}
                <a href="https://instagram.com/ifys.kitchen?utm_medium=copy_link">
                  <img src={instagram} alt="" />
                </a>
                <a href="https://m.facebook.com/IfysKitchen/">
                  <img src={facebook} alt="" />{' '}
                </a>
                <a href="https://twitter.com/ifyskitchen?s=11">
                  <img src={twitter} alt="" />
                </a>
                <a href="https://www.youtube.com/channel/UCYF7UTZ8HpawyohUvyWavPg">
                  <img src={youtube} alt="" />
                </a>{' '}
              </div>
            </div>
          </div>
        </>
      </div>
    </footer>
  );
}

export default Footer;
