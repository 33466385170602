import { BrowserRouter as Router, Route } from "react-router-dom";
import { Suspense, useEffect } from "react";
import { AcceptInvite, Login } from "./pages/Auth/Login";
import useAuth from "./hooks/General/useAuth";
import MainRoutes from "./routes/Main";
import AdminRoutes from "./routes/Admin";
import LeadchefRoutes from "./routes/Leadchef";
import { axiosInstance } from "use-axios-with-redux";
import { useAppDispatch } from "src";
import { clearUserData } from "./redux/Slices/authSlice";
import { TailSpin } from "react-loader-spinner";
import './assets/Stylesheet/base.scss';
// import { axiosInstance } from 'use-axios-with-redux';

const Fallback = () => {
  return (
    <div className='loading--component'>
      <TailSpin/>
      {/* <p> Loading...</p> */}
    </div>
  );
};

function App(): JSX.Element {
  const { isLoggedIn, isRefreshingToken } = useAuth();
  const dispatch = useAppDispatch();
  
  useEffect(() => {
    axiosInstance.interceptors.response.use(
      (config) => {
        return config;
      },
      (error) => {
        if (error.response.status === 401) {
          dispatch(clearUserData());
        }
        return Promise.reject(error);
      }
    );
  }, [dispatch]);
  // eslint-disable-next-line react-hooks/exhaustive-deps

  if (isRefreshingToken && !isLoggedIn) {
    return <div className="loading--component">Loading...</div>;
  }

  return (
    <Suspense fallback={<Fallback />}>
      <Router>
        <LeadchefRoutes isLoggedIn={isLoggedIn} />
        <AdminRoutes isLoggedIn={isLoggedIn} />
        <Route exact path="/:route/login" component={Login} />
        <MainRoutes isLoggedIn={isLoggedIn} />
        <Route exact path="/invite/setup/:token" component={AcceptInvite} />
      </Router>
    </Suspense>
  );
}

export default App;
