import { AxiosError } from 'axios';
import { useHistory } from 'react-router-dom';
import style from '../../sign-in.module.scss';

export default function Header({
  error,
  userType,
}: {
  error: AxiosError | null;
  userType: string;
}) {
  type Thistory = {
    location: {
      state: {
        message: string | null;
      } | null;
    };
  };
  const history = useHistory();

  return (
    <header className={style['header']}>
      <HeaderText userType={userType} />

      {error && <p className={style['error']}>{error.response?.data.message}</p>}
      {(history as Thistory).location.state?.message && !error && (
        <p className={style['accepted__invite']}>{(history as Thistory).location.state?.message}</p>
      )}
    </header>
  );
}

const HeaderText = ({ userType }: { userType: string }) => {
  switch (userType?.toLowerCase()) {
    case 'admin':
      return (
        <>
          <h1>Admin Login</h1>
        </>
      );
    case 'lead-chef':
      return (
        <>
          <h1>Lead Chef Login</h1>
        </>
      );

    default:
      return (
        <>
          <h1>Welcome Back</h1>
          <p>Log in to your account</p>
        </>
      );
  }
};
