import { createPortal } from 'react-dom';
import { ReactComponent as LoadingIcon } from '../../assets/icons/loading-icon.svg';
import style from './loadingScreen.module.scss';
import { useRef } from 'react';


export default function LoadingScreen({ isOpen }: { isOpen: boolean }) {
  const modalsContainer = document.getElementById('modals-container');
  const ref = useRef<HTMLDivElement>(null);

  if (!modalsContainer || !isOpen) return null;
  return createPortal(
    <div data-testid="modal" ref={ref} className={style._}>
      <div>
        <LoadingIcon />
      </div>
    </div>,
    modalsContainer
  );
}
