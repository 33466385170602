import React from 'react';
import './SubscriptionCard.scss';
import ListItem from '../ListItem/ListItem';
import Button from '../Button/button';
import { useHistory } from 'react-router-dom';

function SubscriptionCard({ header, items, plans }) {
    const history = useHistory();
    let styles = [];
    if (header === 'regular' || header === 'Regular') {
        styles = {
            bgColor: 'bg-white',
            headerColor: 'black',
            tickColor: 'blue',
            itemColor: 'black',
            priceColor: 'price-black',
            btnColor: 'white',
            btnBgColor: 'btn-purple',
        };
    } else if (header === 'premium' || header === 'Premium') {
        styles = {
            bgColor: 'bg-blue',
            headerColor: 'white',
            tickColor: 'white',
            itemColor: 'white',
            priceColor: 'white',
            btnColor: 'white',
            btnBgColor: 'btn-purple',
        };
    }

    const handleRoute = () => {
        const planType = header.toString().toLowerCase() === 'regular';

        history.push(`/pre-order/subscription/${planType ? plans[0]?._id : plans[1]?._id}`, {
            plan: header,
        });
    };

    return (
        <div className={`subscription-container ${styles.bgColor}`}>
            <div className="list-container">
                <div className={`subscription-header ${styles.headerColor}`}>{header}</div>
                {items?.map((item, index) => {
                    return (
                        <ListItem
                            key={index}
                            item={item}
                            tickColor={styles.tickColor}
                            itemColor={styles.itemColor}
                        />
                    );
                })}

                {header.toString().toLowerCase() === 'regular' ? (
                    <div className="price-container">
                        <div className={`price full-price ${styles.priceColor}`}>
                            N
                            {plans &&
                                plans[0]?.pricePoints.sort((a, b) => b.price - a.price)[0].price}
                            <span className="per-year"> /year</span>
                        </div>
                        <div className={`price half-price  ${styles.priceColor}`}>
                            N
                            {plans &&
                                plans[0]?.pricePoints.sort((a, b) => b.price - a.price)[1].price}
                            <span className="per-half-year"> /half year</span>
                        </div>
                        <div className={`price month-price ${styles.priceColor}`}>
                            N
                            {plans &&
                                plans[0]?.pricePoints.sort((a, b) => b.price - a.price)[2].price}
                            <span className="per-month"> /month</span>
                        </div>
                    </div>
                ) : (
                    <div className="price-container">
                        <div className={`price full-price ${styles.priceColor}`}>
                            N
                            {plans &&
                                plans[1]?.pricePoints.sort((a, b) => b.price - a.price)[0].price}
                            <span className="per-year"> /year</span>
                        </div>
                        <div className={`price half-price  ${styles.priceColor}`}>
                            N
                            {plans &&
                                plans[1]?.pricePoints.sort((a, b) => b.price - a.price)[1].price}
                            <span className="per-half-year"> /half year</span>
                        </div>
                        <div className={`price month-price ${styles.priceColor}`}>
                            N
                            {plans &&
                                plans[1]?.pricePoints.sort((a, b) => b.price - a.price)[2].price}
                            <span className="per-month"> /month</span>
                        </div>
                    </div>
                )}
            </div>

            <Button variant="secondary" disabled={!plans} onClick={handleRoute}>
                Choose
            </Button>
        </div>
    );
}

export default SubscriptionCard;
