// import Logo from '../../../assets/images/vectors/logo.svg';
// import { ReactComponent as LoginBg } from '../../../assets/images/vectors/login-bg.svg';

import style from '../sign-in.module.scss';
import { useLogin } from './hooks/useLogin';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import FormikControls from '../../../components/FormikControls';
import { AuthLayout } from '../../../components/Layout/Auth';
import { Button } from '../../../components/Button';
import { NavLink, useParams } from 'react-router-dom';
import Header from './components/Header';
import { Helmet } from 'react-helmet';
import { SocialMediaSignIn } from '../../../components/SocialMediaSignIn';
import { BASE_URL } from '../../../utilities/endpoints';
import useNetworkRequest from '../../../hooks/General/useNetworkRequest';
import { useEffect } from 'react';
import { LoginResponseData } from '../../../types/responses';
import { NotificationActionType } from 'src/context/types';
import { useNotificationContext } from 'src/context/NotificationContext/hooks/useNotificationContext';

const Login = (): JSX.Element => {
  const { handleLogin, reset, isLoading, error, authenticate } = useLogin();
  const { dispatch } = useNotificationContext();
  const [
    { isLoading: isSendingRequest, error: googleLoginError, data },
    socialMediaLogin,
    resetGoogleResponse,
  ] = useNetworkRequest<unknown, LoginResponseData>(`${BASE_URL}/auth/google`, {
    method: 'POST',
  });

  const [
    { data: facebookResponse, isLoading: isSendingFacebookAuth, error: facebookLoginError },
    socialMediaLoginFacebook,
    ,
    resetFacebookResponse,
  ] = useNetworkRequest<unknown, LoginResponseData>(`${BASE_URL}/auth/facebook`, {
    method: 'POST',
  });

  const dataResponse = {
    data: {
      user: {
        _id: (data?.data.user._id as string) || facebookResponse?.data.user._id || '',
        name: (data?.data.user.name as string) || facebookResponse?.data.user.name || '',
        email: (data?.data.user.email as string) || facebookResponse?.data.user.email || '',
        phoneNumber:
          (data?.data.user.phoneNumber as number) || facebookResponse?.data.user.phoneNumber || 0,
        userType:
          (data?.data.user.userType as string) || facebookResponse?.data.user.userType || '',
        profileImageUrl:
          (data?.data.user.profileImageUrl as string) ||
          facebookResponse?.data.user.profileImageUrl ||
          '',
        activeSub:
          (data?.data.user.activeSub as boolean) || facebookResponse?.data.user.activeSub || false,
        role: (data?.data.user.role as string) || facebookResponse?.data.user.role || '',
      },
      accessToken: (data?.data.accessToken as string) || facebookResponse?.data.accessToken || '',
      refreshToken:
        (data?.data.refreshToken as string) || facebookResponse?.data.refreshToken || '',
    },
  };

  useEffect(() => {
    if (data || facebookResponse) {
      authenticate(dataResponse);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, facebookResponse]);

  const handleError = () => {
    return facebookLoginError || googleLoginError;
  };
  const handleReset = () => {
    return resetGoogleResponse || resetFacebookResponse;
  };

  useEffect(() => {
    dispatch({
      type: NotificationActionType.ISLOADING,
      payload: isSendingRequest || isSendingFacebookAuth,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isSendingRequest, isSendingFacebookAuth, dispatch]);

  useEffect(() => {
    if (handleError()) {
      dispatch({
        type: NotificationActionType.SHOW_NOTIFICATION,
        payload: {
          type: handleError() ? 'error' : 'success',
          message: handleError() ? handleError()!.message : '',
        },
      });

      handleReset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleError()]);

  const loginSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
    password: Yup.string()
      .min(8, 'Minimum of 8 characters!')
      .max(50, 'Too Long!')
      .required('Required'),
  });
  const { route } = useParams<{ route: string }>();

  return (
    <AuthLayout userType={route}>
      <Helmet>
        <meta name="google-signin-client_id" content={process.env.REACT_APP_GOOGLE_CLIENT_ID} />
      </Helmet>
      <div className={style['login--form']}>
        <Header error={error || googleLoginError} userType={route} />
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          onSubmit={(values, { setSubmitting }) => {
            const email = values.email.toLowerCase();
            reset();
            handleLogin('admin', { email, password: values.password });
            setSubmitting(false);
          }}
          validationSchema={loginSchema}
        >
          <Form className={style['form']}>
            <div className={style['custom__field']}>
              <label htmlFor="">Email</label>
              <FormikControls
                control="input"
                name="email"
                type="text"
                // label="Email Address"
                placeholder="Enter Email"
              />
            </div>
            <div className={style['custom__field']}>
              <label htmlFor="">Password</label>
              <FormikControls
                control="input"
                name="password"
                placeholder="Enter Password"
                type="password"
              />

              <span className={style['action__area']}>
                {!route && (
                  <NavLink to="/forgot-password">
                    <p>Forgot Password?</p>
                  </NavLink>
                )}
              </span>
            </div>

            <Button disabled={isLoading} type="submit" variant={!route ? 'primary' : 'secondary'}>
              {isLoading ? 'Please wait' : 'Login'}
            </Button>
          </Form>
        </Formik>

        {!route && (
          <SocialMediaSignIn
            authenticationType="login"
            loginWithGoogle={socialMediaLogin}
            loginWithFacebook={socialMediaLoginFacebook}
          />
        )}
      </div>
    </AuthLayout>
  );
};

export default Login;
