import { useEffect, useState } from 'react';
import styles from './PreOrderCheckout.module.scss';
import { useHistory } from 'react-router-dom';
import toast from './assets/images/toast.svg';
import LeftArrow from './assets/images/LeftArrow.svg';
import { Button } from '../Button';
import { usePaystackPayment } from 'react-paystack';
import PreOrderModal from '../PreOrderModal/PreOrderModal';

import useNetworkRequest from '../../hooks/General/useNetworkRequest';
import { BASE_URL } from '../../utilities/endpoints';
import { TPreorderScriptResponse } from './types/responses';
import { usePlanPricePoint } from '../../hooks/General/usePlanPricePoint';

type TLocation = {
  state: {
    price: number;
    email: string;
    name: string;
    plan: string;
    type: string;
    details: {
      _id: string;
      price: number;
      subscriptionPlanId: string;
    };
    discount: number;
  };
};

function PreOrderCheckout() {
  const [reference, setReference] = useState('');

  const { location } = useHistory();
  const [modal, setModal] = useState(false);
  const [status, setStatus] = useState('success');

  const handleFinalPrice = (discountPercentage: number, amount: number) => {
    return (discountPercentage / 100) * amount;
  };

  const handleAmountToPay = () => {
    const price = (location as TLocation).state?.details.price;

    return (
      price -
      handleFinalPrice(
        (location as TLocation).state.discount,
        (location as TLocation).state?.details.price
      )
    );
  };

  const payStackConfig = {
    amount: handleAmountToPay() * 100,
    email: (location as TLocation).state?.email,
    name: (location as TLocation).state?.name,
    publicKey: process.env.REACT_APP_PAYSTACK as string,
    reference: reference,
  };

  const initializePayment = usePaystackPayment(payStackConfig);
  const onClose = () => {
    setReference('');
  };

  const onSuccess = () => {
    // Implementation for whatever you want to do with reference and after success call.
    setStatus('success');
    setModal(true);
  };

  const [{ data: response, isLoading: isPaying, error }, sendRequest] = useNetworkRequest<
    unknown,
    TPreorderScriptResponse
  >(`${BASE_URL}/subscription/add-subscription-payment-log`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const handleSubmit = () => {
    sendRequest({
      name: (location as TLocation).state?.name,
      email: (location as TLocation).state?.email,
      pricePointId: (location as TLocation).state?.details._id,
      subscriptionPlanId: (location as TLocation).state?.details.subscriptionPlanId,
      context: 'pre-order',
    });
  };

  useEffect(() => {
    if (response) {
      setReference((response?.data).reference);
    }
  }, [response]);

  useEffect(() => {
    if (error) {
      setStatus('error');
      setModal(true);
    }
  }, [error]);

  useEffect(() => {
    reference && initializePayment(onSuccess, onClose);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reference]);

  const { handlePricePointName } = usePlanPricePoint();

  const history = useHistory();

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <h3>Pre Order Checkout</h3>
        </div>
        <div className={styles.info_wrapper}>
          <div className={styles.wrapper}>
            <h5>Personal Information</h5>
          </div>
          <div className={styles.info_title}>
            <p>Full name</p>
          </div>
          <div className={styles.info}>
            <p>{(location as TLocation).state?.name}</p>
          </div>
          <div className={styles.info_title}>
            <p>Email Address</p>
          </div>
          <div className={styles.info}>
            <p>{(location as TLocation).state?.email}</p>
          </div>
          <div className={styles._order}>
            <h5>Order Summary</h5>
          </div>
          <div className={styles.plan_wrapper}>
            <img src={toast} alt="" />
            <div className={styles.plan}>
              <h5>{`${(location as TLocation).state?.plan} Subscription`}</h5>
              <p>{`${handlePricePointName((location as TLocation).state?.type as string)} Plan`}</p>
            </div>
          </div>
          <div className={styles.price}>
            <div>
              <p>Subtotal</p>
            </div>

            <div className={styles.amount}>
              <p>{`N${(location as TLocation).state?.details.price}`}</p>
            </div>
          </div>
          <div className={styles.price}>
            {(location as TLocation).state?.discount !== 0 && (
              <>
                <p>
                  Discount <span> ({(location as TLocation).state?.discount + '%'}) </span>{' '}
                </p>
                <div className={styles.amount}>
                  <p>{`-N${handleFinalPrice(
                    (location as TLocation).state?.discount,
                    (location as TLocation).state?.details.price
                  )}`}</p>
                </div>
              </>
            )}
          </div>
          <div className={styles.total}>
            {console.log(
              handleFinalPrice(
                (location as TLocation).state?.discount,
                (location as TLocation).state?.details.price
              )
            )}
            <p>{`N${(
              (location as TLocation).state?.details.price -
                handleFinalPrice(
                  (location as TLocation).state?.discount,
                  (location as TLocation).state?.details.price
                ) ?? (location as TLocation).state?.details.price
            ).toLocaleString()}`}</p>
            <h5>Total</h5>
          </div>
          <div className={styles.footer}>
            <div className={styles.mobile_button}>
              <Button onClick={handleSubmit} disabled={isPaying}>
                {isPaying ? 'Please wait...' : `Proceed to Checkout`}
              </Button>
            </div>
            <Button
              variant="plain"
              className={styles['back--button']}
              onClick={() => history.goBack()}
              type="button"
            >
              <img src={LeftArrow} alt="" />
              <span>Back</span>
            </Button>

            <div className={styles.desktop_button}>
              <Button onClick={handleSubmit} disabled={isPaying}>
                {isPaying ? 'Please wait...' : `Proceed to Checkout`}
              </Button>
            </div>
          </div>
        </div>
      </div>

      {modal ? (
        <PreOrderModal
          response={response}
          error={error}
          status={status}
          closeModal={() => setModal(false)}
        />
      ) : null}
    </>
  );
}

export default PreOrderCheckout;
