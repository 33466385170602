import React from 'react';
import { Dispatch } from 'redux';
import ReactDOM from 'react-dom';
import App from './App';
import { store } from './redux/store/store';
import { Provider, TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import 'src/assets/Stylesheet/base.scss';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import NotificationContextProvider from './context/NotificationContext';
import { socket, SocketContext } from './context/Socket/socket';
import PusherContextProvider from './context/PusherContext';
// import { ErrorBoundary } from './components/ErrorBoundary';

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = (): Dispatch => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        {/* <ErrorBoundary> */}
        <NotificationContextProvider>
          <PusherContextProvider>
            <SocketContext.Provider value={socket}>
              <App />
            </SocketContext.Provider>
          </PusherContextProvider>
        </NotificationContextProvider>
        {/* </ErrorBoundary> */}
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
