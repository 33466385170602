import React from 'react';
import { PusherContext } from '..';

export function usePusherContext() {
  const context = React.useContext(PusherContext);

  if (context === undefined) {
    throw new Error('usePusherContext must be used within a PusherContext');
  }

  return context;
}
