import React from "react";
import styles from "./TriviaAnswer.module.css";
import checkIcon from "../../../../assets/images/check-icon.png";
import errorIcon from "../../../../assets/images/error-cross-icon.png";

type decisionEnum = "correct" | "wrong";

interface answerInfoProps {
  decision: decisionEnum;
  answer: string | NumberConstructor;
  handleContinueClick: () => void;
  coupon: boolean;
  setShowCoupon: React.Dispatch<React.SetStateAction<boolean>>;
}

const TriviaAnswer: React.FC<answerInfoProps> = ({
  decision,
  answer,
  handleContinueClick,
}) => {
  let selectedIcon = decision === "correct" ? checkIcon : errorIcon;
  let infoColor = decision === "correct" ? "#219653" : "#EB5757";
  let infoText = decision === "correct" ? "Great Job!" : "Incorrect!";

  return (
    <div className={styles.wrapper__div}>
      <div className={styles.icon}>
        <img src={selectedIcon} alt="icon alt" />
      </div>
      <div className={styles.info__text} style={{ color: infoColor }}>
        {infoText}
      </div>
      <span className={styles.option__text}>
        The correct answer is
        <br></br>
        <b>{answer}</b>
      </span>

      <div className={styles.button__div}>
        <button
          onClick={() => {
            handleContinueClick();
          }}
          className={styles.button}
        >
          Continue
        </button>
      </div>
      <br></br>
      <br></br>
    </div>
  );
};

export default TriviaAnswer;
