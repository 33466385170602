import { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import styles from './PreOrder.module.scss';
import pancake from './assets/images/pancake.svg';
import toast from './assets/images/toast.svg';
import ellipse from './assets/images/ellipse.svg';
import LeftArrow from './assets/images/LeftArrow.svg';
import { Button } from '../../Button';
import { useEffect } from 'react';
import { BASE_URL } from '../../../utilities/endpoints';
import useNetworkRequest from '../../../hooks/General/useNetworkRequest';
import { TPlans } from '../../../types/plans';
import { usePlanPricePoint } from '../../../hooks/General/usePlanPricePoint';
import { TLocation, TSinglePlan } from './types';
import Skeleton from 'react-loading-skeleton';

function PreOrder() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const { id } = useParams<{ id: string }>();

  const location = useLocation();
  const plan = (location as TLocation).state?.plan;
  const type = (location as TLocation).state?.plan || 'Regular';

  const [singlePlan, setSinglePlan] = useState<null | TSinglePlan>(null);

  const [{ data: response, isLoading }, getPlans] = useNetworkRequest<unknown, TPlans>(
    `${BASE_URL}/plans/${id}`,
    {
      method: 'GET',
    }
  );

  useEffect(() => {
    const singlePlanInMemory = JSON.parse(sessionStorage.getItem('singlePlan') as string);

    if (singlePlanInMemory && singlePlanInMemory.planType === plan?.toLocaleLowerCase()) {
      setSinglePlan(singlePlanInMemory);
    } else {
      getPlans();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (response) {
      setSinglePlan(response.data as unknown as TSinglePlan);
      sessionStorage.setItem('singlePlan', JSON.stringify({ ...response.data, plan }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  const [selectedPlan, setSelectedPlan] = useState<string | null>(null);

  const history = useHistory();
  const { handlePricePointName } = usePlanPricePoint();

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2>Ify's Pre Order</h2>
        <p>Please fill in your contact details to get started</p>
      </div>
      <div className={styles.wrapper}>
        <h5>Personal Information</h5>
        <form
          onSubmit={(e) => {
            e.preventDefault();

            if (name.length !== 0 || email.length !== 0 || selectedPlan) {
              history.push('/pre-order/checkout', {
                name,
                email,
                type: selectedPlan,
                plan,
                details: singlePlan?.pricePoints.find(
                  (currentPlan) => selectedPlan === currentPlan.title
                ),
                discount: singlePlan?.discountPercentage,
              });
            }
          }}
        >
          <div className={styles.form_body}>
            <label>Full Name</label>
            <input
              type="text"
              placeholder="James Doe"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>

          <div className={styles.form_body}>
            <label>Email Address</label>
            <input
              type="email"
              placeholder="name@example.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          <div className={styles._order}>
            <h5>
              My Order <span>(Select a plan)</span>{' '}
            </h5>
          </div>
          {isLoading ? (
            <Skeleton height={100} count={3} />
          ) : (
            singlePlan?.pricePoints
              .sort((a, b) => b.price - a.price)
              .map((planItem, index) => (
                <div
                  className={`${styles.plan_wrapper} ${
                    selectedPlan === planItem.title ? styles['selected'] : ''
                  }`}
                  onClick={() => setSelectedPlan(planItem.title)}
                  key={index}
                >
                  <img src={type === 'Regular' ? pancake : toast} alt="" />
                  <div className={styles.plan}>
                    <h5>{`${plan} Subscription`}</h5>
                    <p>
                      {`${handlePricePointName(planItem.title)} Plan`}
                      <img src={ellipse} alt="" />
                      {`N${planItem.price}  only`}
                    </p>
                  </div>
                </div>
              ))
          )}
          <div className={styles.footer}>
            <div className={styles.mobile_button}>
              <Button
                variant="primary"
                type="submit"
                disabled={name.length === 0 || email.length === 0}
              >
                Continue
              </Button>{' '}
            </div>
            <div className={styles.back}>
              <Link to="/">
                <img src={LeftArrow} alt="" />
                <p>Back</p>
              </Link>
            </div>
            <div className={styles.desktop_button}>
              <Button
                variant="primary"
                type="submit"
                disabled={name.length === 0 || email.length === 0 || !selectedPlan}
              >
                Continue
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default PreOrder;
