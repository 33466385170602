import React, { ReactNode, useEffect } from 'react';
import { useReducer } from 'react';
import { useState } from 'react';
import { createContext } from 'react';
import useNetworkRequest from 'src/hooks/General/useNetworkRequest';
import { SingleShopResult } from 'src/hooks/types';
import { TSingleProduct } from 'src/pages/LeadChef/Shop/types';
import { BASE_URL } from 'src/utilities/endpoints';
import { Button } from '../../components/Button';
import { DrawerModal } from '../../components/Modal';
import { Action, ActionKind, State } from '../types';
import style from './index.module.scss';
import useDebounce from 'src/hooks/General/useDebounce';
import { useHistory } from 'react-router-dom';
import { TCartItems } from './types';
import { TailSpin } from 'react-loader-spinner';
import close from '../../assets/images/close.png'


export const CartContext = createContext<
  { state: State; dispatch: React.Dispatch<Action> } | undefined
>(undefined);

const initialState: State = {
  showCart: false,
  cartItems: [],
};

//usereducer function
function reducer(state = initialState, action: Action): State {
  switch (action.type) {
    case 'ADD_ITEM':
      return {
        ...state,
        cartItems: addItemsToCart(state, action.payload as SingleShopResult),
      };
    case 'SUBTRACT_QUANTITY':
      return {
        ...state,
        cartItems: subItem(state, action.payload as SingleShopResult),
      };
    case 'REMOVE_ITEM':
      return {
        ...state,
        cartItems: removeItemFromCart(state, (action.payload as SingleShopResult)._id),
      };
    case 'SHOW_CART':
      return { ...state, showCart: !state.showCart };

    default:
      return state;
  }
}
//actions for adding and removing an item
const removeItemFromCart = (state: State, payload: string) => {
  const result = state.cartItems?.filter((item) => item?._id !== payload);
  return result;
};
const addItemsToCart = (state: State, payload: SingleShopResult) => {
  const productExists: SingleShopResult | undefined = state.cartItems.find(
    (item) => item._id === payload._id
  );

  if (productExists)
    return state.cartItems.map((item) =>
      item._id === payload._id
        ? {
            ...productExists,
            quantity: productExists.quantity + 1,
          }
        : item
    );

  if (state.cartItems.length > 0) {
    return [...state.cartItems, payload];
  } else return [payload];
};

const subItem = (state: State, payload: SingleShopResult) => {
  const productExists = state.cartItems.find((item) => [item._id === payload._id]);
  if (productExists)
    return [
      {
        ...productExists,
        quantity: productExists.quantity && productExists.quantity - 1,
      },
    ];

  if (state.cartItems.length > 0) {
    return [...state.cartItems, payload];
  } else return [payload];
};

//context setup
export default function CartContextProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(reducer, {
    showCart: false,
    cartItems: [],
  });
  const values = { state, dispatch };

  // @ts-expect-error
  const [{ data: cartResponse }, getCartItems] = useNetworkRequest<unknown, TCartItems>(
    `${BASE_URL}/cart`,
    {
      method: 'GET',
    }
  );

  useEffect(() => {
    getCartItems();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // useEffect(()=>{
  //   if(cartResponse){
  //     console.log(cartResponse)
  //   }
  // },[cartResponse])

  useEffect(() => {
    state.cartItems.length > 0 && localStorage.setItem('cart', JSON.stringify(state.cartItems));
  }, [state]);

  return (
    <CartContext.Provider value={values}>
      <DrawerModal
        isOpen={state.showCart}
        onClickOutside={() =>
          dispatch({
            type: ActionKind.SHOW_CART,
          })
        }
      >
        <CartDrawer cartItems={state.cartItems} dispatch={dispatch} />
      </DrawerModal>
      {children}
    </CartContext.Provider>
  );
}

export function useCartUpdate() {
  const context = React.useContext(CartContext);

  if (context === undefined) {
    throw new Error('useCartUpdate must be used within a CartContextProvider');
  }

  return context;
}

type TCardDrawerProps = {
  cartItems: SingleShopResult[];
  dispatch: React.Dispatch<Action>;
};

// @ts-ignore
const CartDrawer = ({ cartItems, dispatch }: TCardDrawerProps) => {
  let history = useHistory();
 
  const [{ data: cartItemsResponse, isLoading: loadingCardItems }, getCartItems] = useNetworkRequest<unknown, TCartItems>(
    `${BASE_URL}/cart`,
    {
      method: 'GET',
    }
  );
  const [action, setAction] = useState('');
  // @ts-ignore
  const [{ data: changeQuantityResponse }, changeQuantity] = useNetworkRequest<
    unknown,
    TSingleProduct
  >(`${BASE_URL}/cart/${action === 'increment' ? 'increment' : 'decrement'}`, {
    method: 'PUT',
  });

  const [{ data: removeResponse, isLoading: removing }, removeItem] = useNetworkRequest<
    unknown,
    TSingleProduct
  >(`${BASE_URL}/cart/decrement`, {
    method: 'PUT',
  });

  // @ts-ignore

  // const addItem = (cartItem: SingleShopResult) => {
  //   dispatch({ type: ActionKind.ADD_ITEM, payload: cartItem });
  // };

  // // @ts-ignore
  // const subtractItem = (cartItem: SingleShopResult) => {
  //   dispatch({
  //     type: ActionKind.SUBTRACT_QUANTITY,
  //     payload: cartItem,
  //   });
  // };

  const [quantity, setquantity] = useState<number>(0);
  const [reduceQuantity, setReduceQuantity] = useState<number>(0);

  // const [currentQuantity, setCurrentQuantity] = useState<number>()

  const debouncedSearchTerm = useDebounce(quantity || reduceQuantity, 800);
  const [activeItemindex, setActiveItemIndex] = useState<number | null>(null);

  const [id, setId] = useState('');

  const handleIncreaseItem = (id: string, index: number) => {
    setActiveItemIndex(index);
    setquantity(quantity + 1);
    setId(id);
    setAction('increment');
  };

  const handleReduceItem = (id: string, index: number) => {
    setActiveItemIndex(index);
    setReduceQuantity(reduceQuantity + 1);
    setId(id);
    setAction('decrement');
  };

  const handleRemoveItem = (index: number, itemId: string, itemQuantity: number) => {
    setActiveItemIndex(index);
    removeItem({
      productId: itemId,
      quantity: itemQuantity,
    });
  };

  const handleCheckout = () => {
    history.push('/shop/cart/checkout');
    dispatch({
      type: ActionKind.SHOW_CART,
    });
  };

  useEffect(() => {
    if (debouncedSearchTerm) {
      changeQuantity({
        productId: id,
        quantity: action === 'increment' ? quantity : reduceQuantity,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (changeQuantityResponse) {
      getCartItems();
      setquantity(0);
      setReduceQuantity(0);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeQuantityResponse]);

  useEffect(() => {
    getCartItems();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeResponse]);

  // useEffect(()=>{
  //   setquantity(0)
  //   setReduceQuantity(0)
  // },[activeItemindex])

  useEffect(() => {
  }, [quantity, reduceQuantity]);




  return (
    <div className={style['cart__wrapper']}>
      
        
      
      {
        loadingCardItems ? 
        <div className={style['loading-state']}>
          <TailSpin/>
        </div>

        :
        cartItemsResponse && cartItemsResponse?.data?.items?.length > 0 ? (
          <>
            <ul className={style['cart__list']}>
              <header>
                <div className={style['cart__title']}>
                  <h4>Cart</h4>
                  <span>{cartItemsResponse?.data?.items?.length} items</span> 
                </div>
                <img src={close} onClick={() =>
                  dispatch({
                    type: ActionKind.SHOW_CART,
                  })} alt="Back" />
              </header>
              {/* {cartItems.map((item, index) => (
                <li className={style['cart__Item']} key={index}>
                  <div className={style['product__img']}>
                    <img src={item.imageUrl as string} alt="" />
                  </div>
                  <div className={style['cart__info']}>
                    <h4>{item.name}</h4>
                    <div className={style['action__area--main']}>
                      <button
                        className={style['add__button']}
                        disabled={item.quantity === 1}
                        onClick={() => subtractItem(item)}
                      >
                        -
                      </button>
                      <span className={style['quantity']}>{item.quantity}</span>
                      <button className={style['subtract__button']} onClick={() => addItem(item)}>
                        +
                      </button>
                    </div>
                  </div>
                  <div className={style['action__area--aside']}>
                    <h4>{item.price && item.price * item.quantity}</h4>
                    <span
                      onClick={() =>
                        dispatch({
                          type: ActionKind.REMOVE_ITEM,
                          payload: {
                            _id: item._id,
                            imageUrl: '',
                            name: '',
                            quantity: 0,
                            price: 0,
                        },
                        })
                      }
                    >
                      Remove Item
                    </span>
                  </div>
                </li>
              ))} */}
              {cartItemsResponse.data.items.map((item, index) => (
                <li className={style['cart__Item']} key={index}>
                  <div className={style['product__img']}>
                    <img src={item._productId.imageUrl as string} alt="" />
                  </div>
                  <div className={style['cart__info']}>
                    <h4>{item._productId.name}</h4>
                    <div className={style['action__area--main']}>
                      <button
                        className={style['add__button']}
                        disabled={item.quantity === 1}
                        onClick={() => handleReduceItem(item._productId._id, index)}
                      >
                        -
                      </button>
                      <span className={style['quantity']}>
                        {(quantity > 0 || reduceQuantity > 0) && activeItemindex === index
                          ? action === 'increment'
                            ? item?.quantity - (reduceQuantity > 0 ? reduceQuantity : 0) + quantity
                            : item?.quantity + (quantity > 0 ? quantity : 0) - reduceQuantity
                          : item.quantity}
                      </span>
                      {/* onClick={() => addItem(item)} */}
                      <button
                        className={style['subtract__button']}
                        onClick={() => handleIncreaseItem(item._productId._id, index)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div className={style['action__area--aside']}>
                    <h4>
                      {item._productId.price
                        ? action === 'increment'
                          ? item._productId.price * item.quantity +
                            (activeItemindex === index ? quantity * item?._productId?.price : 0)
                          : item._productId.price * item.quantity -
                            (activeItemindex === index ? reduceQuantity * item?._productId?.price : 0)
                        : ''}
                    </h4>
                    <span onClick={() => handleRemoveItem(index, item._productId._id, item.quantity)}>
                      {removing && activeItemindex === index ? 'removing....' : 'remove'}
                    </span>
                  </div>
                </li>
              ))}
            </ul>
          </>
        ) : (
          <div className={style['empty__state']}>
            <h4>No Items</h4>
            <Button type="button" variant="secondary">
              Start Shopping
            </Button>
          </div>
      )}
      {cartItemsResponse && cartItemsResponse?.data?.items?.length > 0 && (
        <div className={style['calculate__total']}>
          <div className={style['subtotal__info']}>
            <span>SubTotal</span>
            <span className={style['subtotal__amount']}>
              NGN {cartItemsResponse?.data?.subTotal}
              {/* {cartItems
              // @ts-expect-error
                .reduce((acc, cartItem) => acc +   cartItem.price * cartItem.quantity, 0)
                .toLocaleString()} */}
            </span>
          </div>
          <Button type="button" variant="secondary" onClick={handleCheckout}>
            Checkout
          </Button>
        </div>
      )}
    </div>
  );
};
