import { AxiosError } from 'axios';
import { TSingleClassroomData } from 'src/hooks/types';

export enum classActions {
  DISABLE_COMMENTS = 'DISABLE_COMMENTS',
  ENABLE_COMMENTS = 'ENABLE_COMMENTS',
  ACTIVATE_CLASSROOM = 'ACTIVATE_CLASSROOM',
  END_CLASS = 'END_CLASS',
}

export type TChatAreaProps = {
  singleClass?: TSingleClassroomData['data'];
  setIsComponentVisible: (params: number | boolean) => void;
  handleSidebar: () => void;
  handleClassActions: (params: classActions) => void;
  errorSingleClass: AxiosError | null;
  isActive: TClassroomUpdate['isActive'];
  isDisabled: TClassroomUpdate['commentsDisabled'];
};

export type TClassroomUpdate = {
  name: string;
  description: string;
  commentsDisabled: boolean;
  isActive: boolean;
  attendees: [string, string];
  comments: string[];
  endAt: Date | string;
  startedAt: string;
  endedAt: Date | string;
  _id: string;
  startAt: Date | string;
  thumbnail: string;
  createdAt: string;
  updatedAt: string;
};
