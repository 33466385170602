import { useState } from "react";
import { Button } from "src/components/Button";
import { SubscriptionPlans } from "src/components/SubscriptionPlans";
import DiamondIcon from "src/assets/icons/RegularUser/diamond-icon.svg";

import style from "./subscriptionNotice.module.scss";

const SubscriptionNotice = ({ corpus }: { corpus: string }) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const handleShowSubPlan = () => {
    setModalOpen(true);
  };

  return (
    <div className={style["__container"]}>
      <h4>{corpus} is not included in your subscription plan</h4>
      <p>
        You can upgrade your plan anytime and start engaging with other
        community members.
      </p>

      <Button variant="secondary" type="button" onClick={handleShowSubPlan}>
        <p className={style["subscription-btn__text"]}>
          <img
            className={style["subscription-btn__icon"]}
            src={DiamondIcon}
            alt="diamond icon"
          />
          Upgrade to Premium
        </p>
      </Button>

      <SubscriptionPlans
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
      />
    </div>
  );
};

export default SubscriptionNotice;
